import React, { useState, useEffect } from 'react';
import { Default } from 'react-awesome-spinners';
import {
	PDFViewer,
	Page,
	Text,
	// Link,
	Image,
	View,
	Document,
	StyleSheet,
	Font,
} from '@react-pdf/renderer';
// import { getApplication } from "./../firebase";

// import logo from "./../assets/pdf/logo.png";
import page1 from './../assets/AuthorizationPDF/page1.jpg';
import page2 from './../assets/pdf/page2.jpg';
import page3 from './../assets/pdf/page3.jpg';
import page4 from './../assets/pdf/page4.jpg';

const AuthorizationPDF = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isTimeout, setIsTimeout] = useState(false);
	const [obj, setObj] = useState([]);
	const [date, setDate] = useState(null);

	useEffect(() => {
		async function fetchPost() {
			const pdfData = localStorage.getItem('pdfData');
			const pdfDataJSON = JSON.parse(pdfData);

			console.log({ pdfDataJSON });
			setObj(pdfDataJSON);
			setDate(new Date().toDateString());
			// YourJobStatusDetail

			setTimeout(() => {
				setIsLoading(false);
				setIsTimeout(true);
			}, 500);
		}

		fetchPost();
	}, [obj.length]);

	if (isLoading || !isTimeout) {
		return (
			<div>
				<div style={styles.Ring}>
					<Default />
				</div>
			</div>
		);
	} else {
		return (
			<PDFViewer style={styles.viewer}>
				<Document>
					<Page style={styles.body}>
						<Image style={styles.page1_img} src={page1} />

						{/* Top Section */}
						<View style={{ position: 'absolute' }}>
							<View style={[styles.row, { position: 'absolute' }]}>
								<Text
									style={[
										styles.text,
										{
											position: 'absolute',
											top: '188px',
											left: '150px',
											width: '50px',
										},
									]}
								>
									{obj?.BussinessDetail?.BussinessName || ''}
								</Text>
								<Text
									style={[
										styles.text,
										{
											position: 'absolute',
											top: '188px',
											left: '280px',
											width: '50px',
										},
									]}
								>
									{obj?.BussinessDetail?.Address || ''}
								</Text>
							</View>
							<View style={[styles.row, { position: 'absolute' }]}>
								<Text
									style={[
										styles.text,
										{
											position: 'absolute',
											top: '214px',
											left: '150px',
											width: '50px',
										},
									]}
								>
									{obj?.AboutYourSelf?.FullName || ''}
								</Text>
								<Text
									style={[
										styles.text,
										{
											position: 'absolute',
											top: '214px',
											left: '280px',
											width: '50px',
										},
									]}
								>
									{`${obj?.YourLocation?.StreetAddress} , ${obj?.YourLocation?.City} , ${obj?.YourLocation?.State} , ${obj?.YourLocation?.Zip}`}
								</Text>
							</View>
							<View style={[styles.row, { position: 'absolute' }]}>
								<Text
									style={[
										styles.text,
										{
											position: 'absolute',
											top: '248px',
											left: '150px',
											width: '50px',
										},
									]}
								>
									{obj?.AboutYourCoAplicant?.FullName || ''}
								</Text>
								<Text
									style={[
										styles.text,
										{
											position: 'absolute',
											top: '248px',
											left: '280px',
											width: '50px',
										},
									]}
								>
									{`${obj?.CoAplicantLocation?.streetAddress} , ${obj?.CoAplicantLocation?.city} , ${obj?.CoAplicantLocation?.state} , ${obj?.CoAplicantLocation?.zip}`}
								</Text>
							</View>
						</View>

						{/* Signature Section Only*/}
						<View style={[styles.text, { position: 'absolute', width: '100%' }]}>
							{/* Authorized Signor */}
							<View
								style={[
									styles.row,
									{
										position: 'absolute',
										top: '475px',
										left: '120px',
									},
								]}
							>
								<Text style={{ width: '70px' }}>{obj?.BussinessDetail?.SignorSignature || ''}</Text>
								{/* GAP */}
								<View style={{ width: '100px' }}></View>
								<Text style={{ width: '70px' }}>{obj?.BussinessDetail?.BussinessName || ''}</Text>
								<View style={{ width: '100px' }}></View>
								<Text style={{ width: '70px' }}>{date}</Text>
							</View>
							{/* Indicidual signature */}
							<View
								style={[
									styles.row,
									{ position: 'absolute', top: '509px', left: '120px', height: '9px' },
								]}
							>
								<Text style={{ width: '70px' }}></Text>
								<View style={{ width: '100px' }}></View>
								<Text style={{ width: '70px' }}>{obj?.AboutYourSelf?.FullName || ''}</Text>
								<View style={{ width: '100px' }}></View>
								<Text style={{ width: '70px' }}>{date}</Text>
							</View>
							{/* Spouse Signature */}
							<View
								style={[
									styles.row,
									{ position: 'absolute', top: '540px', left: '120px', height: '9px' },
								]}
							>
								<Text style={{ width: '70px' }}></Text>
								<View style={{ width: '100px' }}></View>
								<Text style={{ width: '70px' }}>{obj?.AboutYourCoAplicant?.FullName || ''}</Text>
								<View style={{ width: '100px' }}></View>
								<Text style={{ width: '70px' }}>{date}</Text>
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		);
	}
};

Font.register({
	family: 'Oswald',
	src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
	viewer: {
		width: window.innerWidth, //the pdf viewer will take up all of the width and height
		height: window.innerHeight,
	},
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	page1_img: {},
	title: {
		fontSize: 24,
		textAlign: 'center',
		fontFamily: 'Oswald',
	},
	author: {
		fontSize: 12,
		textAlign: 'center',
		marginBottom: 40,
	},
	subtitle: {
		fontSize: 18,
		margin: 12,
		fontFamily: 'Oswald',
	},
	image: {
		marginVertical: 15,
		marginHorizontal: 100,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},

	row: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		// alignItems: "baseline",
		// alignContent: "baseline",
	},
	col: {
		margin: 0,
	},
	item: {
		fontFamily: 'Poppins',
		fontSize: 10,
		color: '#000',
	},

	txtLeft: {
		textAlign: 'left',
	},
	txtRight: {
		textAlign: 'right',
	},
	txtCenter: {
		textAlign: 'center',
	},

	Border: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#DAE6ED',
	},
	BorderTop: {
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderTopColor: '#DAE6ED',
	},
	BorderRight: {
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DAE6ED',
	},
	BorderBottom: {
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#DAE6ED',
	},
	BorderLeft: {
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',
		borderLeftColor: '#DAE6ED',
	},
	BorderNo: {
		border: 0,
	},

	width16: {
		width: '16.5%',
	},
	width25: {
		width: '25%',
	},
	width30: {
		width: '30%',
	},
	width33: {
		width: '33.33%',
	},
	width35: {
		width: '35%',
	},
	width50: {
		width: '50%',
	},
	width65: {
		width: '65%',
	},
	width75: {
		width: '75%',
	},
	width80: {
		width: '80%',
	},
	width90: {
		width: '90%',
	},
	width100: {
		width: '100%',
	},

	text: {
		margin: 1.8,
		fontSize: 6.5,
		textAlign: 'justify',
		fontFamily: 'Times-Roman',
	},
	applicant_co_applicant: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '165px',
		left: '35px',
	},
	CompleteForm: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '377px',
		left: '35px',
	},
	CompleteForm2: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '377px',
		left: '300px',
	},
	YourJobStatusDetail: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '92px',
		left: '252px',
		width: '45px',
	},
	YourJobExpense: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '92px',
		left: '515px',
		width: '45px',
	},
	CashSaving: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '338px',
		left: '35px',
	},
	InsurancePolicy: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '586px',
		left: '35px',
	},
	ShareBond: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '457px',
		left: '35px',
	},
	MortgageContract: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '72px',
		left: '35px',
	},
	RealEstatePersonal: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '158px',
		left: '35px',
	},
	RealEstateInvestment: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '205px',
		left: '35px',
	},
	RealEstateInvestmentNo: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '279px',
		left: '35px',
	},
	ProfitPension: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '376px',
		left: '35px',
	},
	PayableLoan: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '484px',
		left: '35px',
	},
	ContingentLiabilities: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '93px',
		left: '35px',
	},
	ContingentLiabilitiesQuestionnaire: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '143px',
		left: '366px',
	},
	ContingentLiabilitiesQuestionnaireOther: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '229px',
		left: '338px',
	},
	ApplicantTotalEstimatedTaxLiabilityInformation: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '285px',
		left: '35px',
		padding: 2,
	},
});

export default AuthorizationPDF;
