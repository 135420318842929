import axios from 'axios';

function findGetParameter(parameterName) {
    var result = null,
    tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

const DocusignAuth = () => {
    const Authorization = "YTk2N2YzMzQtNGI2Yy00OTQ2LWI4M2QtY2EyNTQzNmFlNmQxOmJhMjhlNTY2LTczODgtNGUzNC1hMzFiLWVmODkzNGU2MjRhNg==";
    const code = findGetParameter("code");
    if(code) {
        localStorage.setItem("docusign_code", code);

        let docusignBody = new URLSearchParams();
        docusignBody.append("code", code);
        docusignBody.append("grant_type", "authorization_code");

        let docusignHeader = new Headers();
        // docusignHeader.append("", "");
        docusignHeader.append("Authorization", "Basic " + Authorization );
        docusignHeader.append("Content-Type", "application/x-www-form-urlencoded");
        docusignHeader.append("Access-Control-Allow-Origin", "*");
        docusignHeader.append("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");
        docusignHeader.append("Accept", "*/*");
        docusignHeader.append("Content-Length", docusignBody.length);
        docusignHeader.append("Host", "http://localhost:3000");
        docusignHeader.append("Accept-Encoding", "gzip, deflate, br");
        docusignHeader.append("Connection", "keep-alive");
        
        var requestOptions = {
          method: 'POST',
          headers: docusignHeader,
          body: docusignBody,
          redirect: 'follow'
        };

        console.log("requestOptions", requestOptions);
        
        fetch("https://account-d.docusign.com/oauth/token", requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));

        /*
        let data = {
            'code': code,
            'grant_type': 'authorization_code' 
        };

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://account-d.docusign.com/oauth/token',
            headers: { 
                'Authorization': 'Basic '+Authorization, 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
            data : data
        };

        axios.request(config)
        .then((response) => {
            console.log("response", response, response.data);
        })
        .catch((error) => {
            console.log(error);
        });
        */

    }
    
    console.log(axios.isCancel('axios isCancel something wrong'));

    return (
        <div className="app">
            {code}
        </div>
    );
}

export default DocusignAuth;
