import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const ContingentLiabilitiesQuestionnaireOther = () => {
	const {
		register,
		handleSubmit,
		// watch,
		// formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [applicantLettersCreditSuretyBonds, setApplicantLettersCreditSuretyBonds] = useState('');
	const [coApplicantLettersCreditSuretyBonds, setCoApplicantLettersCreditSuretyBonds] = useState('');
	const [applicantLettersCreditSuretyBondsTA, setApplicantLettersCreditSuretyBondsTA] = useState('');
	const [applicantSuitsLegalActions, setApplicantSuitsLegalActions] = useState('');
	const [coApplicantSuitsLegalActions, setCoApplicantSuitsLegalActions] = useState('');
	const [applicantSuitsLegalActionsTA, setApplicantSuitsLegalActionsTA] = useState('');
	const [applicantTaxObligations, setApplicantTaxObligations] = useState('');
	const [coApplicantTaxObligations, setCoApplicantTaxObligations] = useState('');
	const [applicantTaxObligationsTA, setApplicantTaxObligationsTA] = useState('');
	const [applicantReturnsCurrentlyBeingContested, setApplicantReturnsCurrentlyBeingContested] = useState('');
	const [coApplicantReturnsCurrentlyBeingContested, setCoApplicantReturnsCurrentlyBeingContested] = useState('');
	const [applicantReturnsCurrentlyBeingContestedTA, setApplicantReturnsCurrentlyBeingContestedTA] = useState('');
	const [applicantTotalEstimatedTaxLiability, setApplicantTotalEstimatedTaxLiability] = useState('');
	const [coApplicantTotalEstimatedTaxLiability, setCoApplicantTotalEstimatedTaxLiability] = useState('');
	const [applicantTotalEstimatedTaxLiabilityOther, setApplicantTotalEstimatedTaxLiabilityOther] = useState('');
	const [applicantTotalEstimatedTaxLiabilityInformation, setApplicantTotalEstimatedTaxLiabilityInformation] =
		useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		const saveData = {
			applicantLettersCreditSuretyBonds,
			coApplicantLettersCreditSuretyBonds,
			applicantLettersCreditSuretyBondsTA,
			applicantSuitsLegalActions,
			coApplicantSuitsLegalActions,
			applicantSuitsLegalActionsTA,
			applicantTaxObligations,
			coApplicantTaxObligations,
			applicantTaxObligationsTA,
			applicantReturnsCurrentlyBeingContested,
			coApplicantReturnsCurrentlyBeingContested,
			applicantReturnsCurrentlyBeingContestedTA,
			applicantTotalEstimatedTaxLiability,
			coApplicantTotalEstimatedTaxLiability,
			applicantTotalEstimatedTaxLiabilityOther,
			applicantTotalEstimatedTaxLiabilityInformation,
		};
		console.log({ saveData });

		await updateApplication(createApplicationDefault, {
			ContingentLiabilitiesQuestionnaireOther: saveData,
		});
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/complete-form');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setApplicantLettersCreditSuretyBonds(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantLettersCreditSuretyBonds
		);
		setCoApplicantLettersCreditSuretyBonds(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.coApplicantLettersCreditSuretyBonds
		);
		setApplicantLettersCreditSuretyBondsTA(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantLettersCreditSuretyBondsTA
		);
		setApplicantSuitsLegalActions(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantSuitsLegalActions
		);
		setCoApplicantSuitsLegalActions(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.coApplicantSuitsLegalActions
		);
		setApplicantSuitsLegalActionsTA(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantSuitsLegalActionsTA
		);
		setApplicantTaxObligations(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantTaxObligations
		);
		setCoApplicantTaxObligations(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.coApplicantTaxObligations
		);
		setApplicantTaxObligationsTA(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantTaxObligationsTA
		);
		setApplicantReturnsCurrentlyBeingContested(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantReturnsCurrentlyBeingContested
		);
		setCoApplicantReturnsCurrentlyBeingContested(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.coApplicantReturnsCurrentlyBeingContested
		);
		setApplicantReturnsCurrentlyBeingContestedTA(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantReturnsCurrentlyBeingContestedTA
		);
		setApplicantTotalEstimatedTaxLiability(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiability
		);
		setCoApplicantTotalEstimatedTaxLiability(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.coApplicantTotalEstimatedTaxLiability
		);
		setApplicantTotalEstimatedTaxLiabilityOther(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiabilityOther
		);
		setApplicantTotalEstimatedTaxLiabilityInformation(
			getApplicationDefault.ContingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiabilityInformation
		);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};
	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<form onSubmit={handleSubmit(onSubmit)}>
					<h2 className='step-head-blue pulse text-center py-3'>
						Contingent Liabilities Questionaire Other...
					</h2>
					<Row className='py-2 '>
						<Col xs={5}>
							<h5 className='py-2 font-bold py-5'>
								Do you have outstanding Letters of Credit or Surety Bonds?
							</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantLettersCreditSuretyBonds_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantLettersCreditSuretyBonds_1`}
										{...register('.ApplicantLettersCreditSuretyBonds')}
										onChange={(e) => setApplicantLettersCreditSuretyBonds(e.target.value)}
										checked={applicantLettersCreditSuretyBonds === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantLettersCreditSuretyBonds_2`}
										{...register('.ApplicantLettersCreditSuretyBonds')}
										onChange={(e) => setApplicantLettersCreditSuretyBonds(e.target.value)}
										checked={applicantLettersCreditSuretyBonds === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`CoApplicantLettersCreditSuretyBonds_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantLettersCreditSuretyBonds_1`}
										{...register('.CoApplicantLettersCreditSuretyBonds')}
										onChange={(e) => setCoApplicantLettersCreditSuretyBonds(e.target.value)}
										checked={coApplicantLettersCreditSuretyBonds === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantLettersCreditSuretyBonds_2`}
										{...register('.CoApplicantLettersCreditSuretyBonds')}
										onChange={(e) => setCoApplicantLettersCreditSuretyBonds(e.target.value)}
										checked={coApplicantLettersCreditSuretyBonds === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5'>
							<h6 className='font-bold'>Total Amount</h6>
							<input
								type='text'
								{...register('.ApplicantLettersCreditSuretyBondsTA')}
								onChange={(e) => setApplicantLettersCreditSuretyBondsTA(sanitizeValue(e.target.value))}
								value={parseCurrency(applicantLettersCreditSuretyBondsTA)}
							/>
						</Col>
					</Row>

					<Row className='py-2 '>
						<Col xs={5}>
							<h5 className='py-2 font-bold py-5'>
								Are there any suits or legal actions pending against you?
							</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantSuitsLegalActions_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantSuitsLegalActions_1`}
										{...register('.ApplicantSuitsLegalActions')}
										onChange={(e) => setApplicantSuitsLegalActions(e.target.value)}
										checked={applicantSuitsLegalActions === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantSuitsLegalActions_2`}
										{...register('.ApplicantSuitsLegalActions')}
										onChange={(e) => setApplicantSuitsLegalActions(e.target.value)}
										checked={applicantSuitsLegalActions === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`CoApplicantSuitsLegalActions_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantSuitsLegalActions_1`}
										{...register('.CoApplicantSuitsLegalActions')}
										onChange={(e) => setCoApplicantSuitsLegalActions(e.target.value)}
										checked={coApplicantSuitsLegalActions === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantSuitsLegalActions_2`}
										{...register('.CoApplicantSuitsLegalActions')}
										onChange={(e) => setCoApplicantSuitsLegalActions(e.target.value)}
										checked={coApplicantSuitsLegalActions === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5'>
							<h6 className='font-bold'>Total Amount</h6>
							<input
								type='text'
								{...register('.ApplicantSuitsLegalActionsTA')}
								onChange={(e) => setApplicantSuitsLegalActionsTA(sanitizeValue(e.target.value))}
								value={parseCurrency(applicantSuitsLegalActionsTA)}
							/>
						</Col>
					</Row>

					<Row className='py-2 '>
						<Col xs={5}>
							<h5 className='py-2 font-bold py-5'>Are any of your tax obligations past due?</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantTaxObligations_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantTaxObligations_1`}
										{...register('.ApplicantTaxObligations')}
										onChange={(e) => setApplicantTaxObligations(e.target.value)}
										checked={applicantTaxObligations === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantTaxObligations_2`}
										{...register('.ApplicantTaxObligations')}
										onChange={(e) => setApplicantTaxObligations(e.target.value)}
										checked={applicantTaxObligations === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`CoApplicantTaxObligations_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantTaxObligations_1`}
										{...register('.CoApplicantTaxObligations')}
										onChange={(e) => setCoApplicantTaxObligations(e.target.value)}
										checked={coApplicantTaxObligations === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantTaxObligations_2`}
										{...register('.CoApplicantTaxObligations')}
										onChange={(e) => setCoApplicantTaxObligations(e.target.value)}
										checked={coApplicantTaxObligations === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5'>
							<h6 className='font-bold'>Total Amount</h6>
							<input
								type='text'
								{...register('.ApplicantTaxObligationsTA')}
								onChange={(e) => setApplicantTaxObligationsTA(sanitizeValue(e.target.value))}
								value={parseCurrency(applicantTaxObligationsTA)}
							/>
						</Col>
					</Row>

					<Row className='py-2 '>
						<Col xs={5}>
							<h5 className='py-2 font-bold py-5'>
								Income tax returns are filed through (date): Are any returns currently being contested?
							</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							{['radio'].map((type) => (
								<div key={`ApplicantReturnsCurrentlyBeingContested_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`ApplicantReturnsCurrentlyBeingContested _1`}
										{...register('.ApplicantReturnsCurrentlyBeingContested')}
										onChange={(e) => setApplicantReturnsCurrentlyBeingContested(e.target.value)}
										checked={applicantReturnsCurrentlyBeingContested === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`ApplicantReturnsCurrentlyBeingContested_2`}
										{...register('.ApplicantReturnsCurrentlyBeingContested')}
										onChange={(e) => setApplicantReturnsCurrentlyBeingContested(e.target.value)}
										checked={applicantReturnsCurrentlyBeingContested === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							{['radio'].map((type) => (
								<div key={`CoApplicantReturnsCurrentlyBeingContested_${type}`} className='mb-3 py-3'>
									<Form.Check
										inline
										label='Yes'
										value='Yes'
										type={type}
										id={`CoApplicantReturnsCurrentlyBeingContested_1`}
										{...register('.CoApplicantReturnsCurrentlyBeingContested')}
										onChange={(e) => setCoApplicantReturnsCurrentlyBeingContested(e.target.value)}
										checked={coApplicantReturnsCurrentlyBeingContested === 'Yes'}
									/>
									<Form.Check
										inline
										label='No'
										value='No'
										type={type}
										id={`CoApplicantReturnsCurrentlyBeingContested_2`}
										{...register('.CoApplicantReturnsCurrentlyBeingContested')}
										onChange={(e) => setCoApplicantReturnsCurrentlyBeingContested(e.target.value)}
										checked={coApplicantReturnsCurrentlyBeingContested === 'No'}
									/>
								</div>
							))}
						</Col>
						<Col className='text-center my-5'>
							<h6 className='font-bold'>If Yes, what date:</h6>
							<input
								type='date'
								{...register('.applicantReturnsCurrentlyBeingContestedTA')}
								onChange={(e) => setApplicantReturnsCurrentlyBeingContestedTA(e.target.value)}
								value={applicantReturnsCurrentlyBeingContestedTA}
							/>
						</Col>
					</Row>
					<Row className='py-2 '>
						<Col xs={5}>
							<h5 className='py-2 font-bold py-5'>
								What would be your total estimated tax liability if you were to sell your major assets?
							</h5>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Applicant</h6>
							<input
								type='text'
								{...register('.applicantTotalEstimatedTaxLiability')}
								onChange={(e) => setApplicantTotalEstimatedTaxLiability(e.target.value)}
								value={applicantTotalEstimatedTaxLiability}
							/>
						</Col>
						<Col className='text-center my-5 questionair'>
							<h6 className='font-bold'>Co-Applicant</h6>
							<input
								type='text'
								{...register('.coApplicantTotalEstimatedTaxLiability')}
								onChange={(e) => setCoApplicantTotalEstimatedTaxLiability(e.target.value)}
								value={coApplicantTotalEstimatedTaxLiability}
							/>
						</Col>
						<Col className='text-center my-5'>
							<h6 className='font-bold'>Other</h6>
							<input
								type='text'
								{...register('.applicantTotalEstimatedTaxLiabilityOther')}
								onChange={(e) => setApplicantTotalEstimatedTaxLiabilityOther(e.target.value)}
								value={applicantTotalEstimatedTaxLiabilityOther}
							/>
						</Col>
					</Row>

					<Row className='py-2 '>
						<Col xs={5}>
							<h5 className='py-2 font-bold py-5'>If yes for any of the above, give details:</h5>
						</Col>
						<Col className='my-5 questionair'>
							<h6 className='font-bold'>Detail Information</h6>
							<input
								type='textarea'
								{...register('.applicantTotalEstimatedTaxLiabilityInformation')}
								onChange={(e) => setApplicantTotalEstimatedTaxLiabilityInformation(e.target.value)}
								value={applicantTotalEstimatedTaxLiabilityInformation}
							/>
						</Col>
					</Row>

					<Row className='py-5 justify-content-between'>
						<Col xs={5} md={3}>
							<Link
								to='/dashboard/contingent-liabilities-questionnaire'
								className='cl-btn position-absolute'
							>
								{' '}
								Back{' '}
							</Link>
						</Col>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
