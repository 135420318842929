import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const CashSaving = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([
		/*{
      NameOfBank: "",
      TypeAccount: "",
      WhoseName: "",
      AccountBalance: "",
      group: "",
    }*/
	]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		// Convert arr to the format of data. Using arr here becuase data is raw and arr is sanitized
		// arr is sanitized in updateInput function
		const saveData = {};
		arr.map((item, i) => {
			saveData[i] = item;
		});
		console.log({ saveData });

		updateApplication(createApplicationDefault, { CashSaving: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/insurance-policy-flow');
	};

	// console.log("watch register", watch(register));
	// console.log("errors", errors);

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		// console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.CashSaving));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addInput = () => {
		setArr((s) => {
			return [
				...s,
				{
					NameOfBank: '',
					TypeAccount: '',
					WhoseName: '',
					AccountBalance: '',
					group: '',
				},
			];
		});
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};
	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const updateInput = (evnt, index, ele) => {
		const { value } = evnt.target;
		const list = [...arr];
		if (ele === 'AccountBalance') {
			list[index][ele] = sanitizeValue(value);
			console.log({ value: sanitizeValue(value) });
		} else list[index][ele] = value;
		setArr(list);
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Let us know about your Cash and Savings...</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row className='py-5 mb'>
								<Col xs={4}>
									<label>Name of Bank, Financial Institution or Brokerage Firm</label>
									<input
										type='text'
										{...register(i + '.NameOfBank')}
										onChange={(e) => updateInput(e, i, 'NameOfBank')}
										value={item.NameOfBank}
									/>
									{errors[i] && errors[i].NameOfBank && <span>Name of Bank is required*</span>}
								</Col>

								<Col xs={4}>
									<label>Type of Account</label>
									<input
										type='text'
										{...register(i + '.TypeAccount')}
										onChange={(e) => updateInput(e, i, 'TypeAccount')}
										value={item.TypeAccount}
									/>
									{errors[i] && errors[i].TypeAccount && <span>Type of Account Required*</span>}
								</Col>

								<Col xs={4}>
									<label>In Whose Name?</label>
									<input
										type='text'
										{...register(i + '.WhoseName')}
										onChange={(e) => updateInput(e, i, 'WhoseName')}
										value={item.WhoseName}
									/>
									{errors[i] && errors[i].WhoseName && <span>In Whose Name required*</span>}
								</Col>

								<Col xs={6}>
									<label>Account Balance ($)</label>
									<input
										type='text'
										{...register(i + '.AccountBalance')}
										onChange={(e) => updateInput(e, i, 'AccountBalance')}
										value={parseCurrency(item.AccountBalance)}
										placeholder='0'
									/>
									{errors[i] && errors[i].AccountBalance && <span>Account Balance Required*</span>}
								</Col>

								<Col xs={6}>
									<label>Pledged?</label>
									{['radio'].map((type) => (
										<div key={`group-${i}-${type}`} className='mb-3 py-3'>
											<Form.Check
												inline
												label='Yes'
												value='Yes'
												type={type}
												id={`group_${i}_1`}
												onChange={(e) => updateInput(e, i, 'group')}
												defaultChecked={item.group === 'Yes'}
												{...register(i + '.group')}
											/>
											<Form.Check
												inline
												label='No'
												value='No'
												type={type}
												id={`group_${i}_2`}
												onChange={(e) => updateInput(e, i, 'group')}
												defaultChecked={item.group === 'No'}
												{...register(i + '.group')}
											/>
										</div>
									))}
									{errors[i] && errors[i].group && <span>Pledged Required*</span>}
								</Col>
							</Row>
						);
					})}

					{/*
          <Row className="py-3 justify-content-md-center">
            <Col xs={3}>
              <b>
                <h4 className="text-center font-bold secondary">what is the fixed "total valuation"? $1245</h4>
              </b>
            </Col>
          </Row>
*/}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/* <Row>
            <Col className="text-center">
              <Link to="/dashboard/cash-saving-flow" className="btn btn-outline-primary mr-3">Back</Link>
              <input className="cl-btn" type="submit" value="Submit & Continue" />
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between'>
						<Col xs={5} md={3}>
							<Link to='/dashboard/cash-saving-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
