import React, { useEffect, useState } from "react";
import { Header } from "../dashboard/Header";
import { Container, Row, Col, Dropdown, Stack, Badge } from "react-bootstrap";
import AppIcon from "../../assets/img/application-icon.png";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { fetchApplications, checkAdmin } from "../../firebase";
import { useNavigate } from "react-router-dom";
import styles from "./admin.module.css";

export default function AdminPage() {
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user)
        checkAdmin(user.uid).then((res) => {
          if (!res) navigate("/login");
          console.log("Admin");
        });
      else navigate("/login");
    });
    return () => unsubscribe();
  }, []);

  const [applications, setApplications] = useState([]);
  const [timespan, setTimespan] = useState("daily"); // ['week', 'month', 'year']
  const [newState, setNewState] = useState("temp");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserApplications = async (timespan) => {
      setLoading(true);
      setApplications([]);
      try {
        const applications = await fetchApplications(timespan);
        setApplications(applications);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchUserApplications(timespan);
  }, [timespan]);

  return (
    <div>
      <Header />
      <Container>
        <div className="d-flex justify-content-end gap-5 align-items-end my-5">
          <div>
            <div>
              <label className="fw-semibold fs-5">
                Select the timespan for reports
              </label>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <div>
                <Stack direction="horizontal" gap={2}>
                  <Badge
                    role="button"
                    onClick={() => setTimespan("daily")}
                    className={`border ${timespan !== "daily" && "text-dark"}`}
                    bg={timespan === "daily" ? "info" : "light"}
                  >
                    Daily
                  </Badge>
                  <Badge
                    onClick={() => setTimespan("weekly")}
                    role="button"
                    className={`border ${timespan !== "weekly" && "text-dark"}`}
                    bg={timespan === "weekly" ? "info" : "light"}
                  >
                    Weekly
                  </Badge>
                  <Badge
                    onClick={() => setTimespan("monthly")}
                    role="button"
                    className={`border ${
                      timespan !== "monthly" && "text-dark"
                    }`}
                    bg={timespan === "monthly" ? "info" : "light"}
                  >
                    Monthly
                  </Badge>
                  <Badge
                    onClick={() => setTimespan("annually")}
                    role="button"
                    className={`border ${
                      timespan !== "annually" && "text-dark"
                    }`}
                    bg={timespan === "annually" ? "info" : "light"}
                  >
                    Annually
                  </Badge>
                </Stack>
              </div>
            </div>
          </div>
        </div>
        <Row className="py-5 my-3 g-4">
          {applications?.map((record, index) => (
            <Record record={record} key={index} />
          ))}
          {applications?.length === 0 && !loading && (
            <h4 className="text-center">
              No applications found for the given time slot.
            </h4>
          )}
          {loading && (
            <div className="position-absolute top-50 start-50">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
}

const Record = (props) => {
  const navigate_edit = async (url, props) => {
    let pdfData = props.record;
    localStorage.setItem("pdfData", JSON.stringify(pdfData));
    localStorage.setItem("pdfDataID", pdfData.id);

    // const auth = getAuth();
    // const user = auth.currentUser;
    // localStorage.setItem('user', JSON.stringify(user));
    // console.log({ user, auth })

    localStorage.setItem("createApplicationDefault", props.record.id);
    // console.log("navigate_edit createApplicationDefault", props.record.id);

    setTimeout(() => {
      window.open(url);
    }, 1000);
  };

  const convertStringIntoDate = (dateString) => {
    const getDaySuffix = (day) => {
      if (day % 10 === 1 && day !== 11) {
        return "st";
      } else if (day % 10 === 2 && day !== 12) {
        return "nd";
      } else if (day % 10 === 3 && day !== 13) {
        return "rd";
      } else {
        return "th";
      }
    };

    const dateObj = new Date(dateString);

    // Convert to PST timezone
    const pstDateObj = new Date(
      dateObj.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    // Format the date
    const formattedDate = pstDateObj.toLocaleString("en-US", options);

    // Add suffix for the day
    const dayWithSuffix = formattedDate.replace(
      /(\d+)(st|nd|rd|th)/,
      (_, day) => `${day}${getDaySuffix(day)}`
    );

    return dayWithSuffix;
  };

  const [message, setMessage] = useState(true);
  return props.record.status === "COMPLETED" ? (
    <Col xs={3} key={props.record.id}>
      <div className="application-container application-complete">
        <div>
          <div className="text-center py-2">
            <img src={AppIcon} width="50px" alt="APPLICATION COMPLETED" />
          </div>
          <h6 className="text-center font-bold">APPLICATION ID:</h6>
          <h6 className="date text-center font-normal">#{props.record.id}</h6>
          <p className="font-complete py-2 text-center font-bold">COMPLETED</p>
          <p className="date text-center font-normal">
            <b>Submited:</b> {convertStringIntoDate(props.record.updated)}
          </p>
          <Dropdown onToggle={() => setMessage(!message)}>
            <Dropdown.Toggle
              as={CustomToggle}
              variant="success"
              message={message}
              setMessage={setMessage}
              id="dropdown-basic"
            />
            <Dropdown.Menu>
              <Dropdown.Item
                className="my-2 date font-bold text-center font-normal"
                onClick={() => navigate_edit("/#/pdf/", props)}
              >
                Original PFS - CL
              </Dropdown.Item>
              <Dropdown.Item
                className="my-2 date font-bold text-center font-normal"
                onClick={() => navigate_edit("/#/BusinessPurposePDF/", props)}
              >
                Business Purpose Loan Certification LLC - CL
              </Dropdown.Item>
              <Dropdown.Item
                className="my-2 date font-bold text-center font-normal"
                onClick={() => navigate_edit("/#/BankingDisclosurePDF/", props)}
              >
                E- Banking disclosure - CL
              </Dropdown.Item>
              <Dropdown.Item
                className="my-2 date font-bold text-center font-normal"
                onClick={() => navigate_edit("/#/BusinessLoanPDF/", props)}
              >
                Business loan checklist - CL
              </Dropdown.Item>
              <Dropdown.Item
                className="my-2 date font-bold text-center font-normal"
                onClick={() => navigate_edit("/#/AuthorizationPDF/", props)}
              >
                Authorization - CL
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <p
            onClick={() =>
              window.open(
                "https://creativelending.securefilepro.com/portal/#/login"
              )
            }
            className="round-outline-preview date py-2 px-1 font-bold text-center font-normal none"
          >
            Secure Document Upload
          </p>
          {/* <p className="round-outline-preview date py-2 font-bold text-center font-normal" onClick={() => docusign("https://account-d.docusign.com/oauth/auth?response_type=code&scope=user_read user_write organization_read account_read group_read permission_read identity_provider_read domain_read&client_id=a967f334-4b6c-4946-b83d-ca25436ae6d1&redirect_uri=http://localhost:3000/react-oauth-docusign/build", props)}>Docusign</p> */}
        </div>
      </div>
    </Col>
  ) : (
    <Col xs={3} key={props.record.id}>
      <div className="application-pending application-container">
        <div>
          <div className="text-center py-2">
            <img src={AppIcon} width="50px" alt="APPLICATION PENDING" />
          </div>
          <h6 className="text-center font-bold">APPLICATION ID:</h6>
          <h6 className="date text-center font-normal">#{props.record.id}</h6>
          <p className="font-pending py-2 text-center font-bold">PENDING</p>
          <p className="date text-center font-normal">
            <b>Last Edited:</b> {convertStringIntoDate(props.record.updated)}
          </p>
        </div>
      </div>
    </Col>
  );
};

const CustomToggle = React.forwardRef(
  ({ onClick, message, setMessage }, ref) => (
    <button
      className="round-outline-preview date py-2 font-bold text-center font-normal w-100 d-flex justify-content-end gap-4 pe-4 t align-items-center"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* {children} */}
      <div>{message ? "Preview Application" : "Hide Application"}</div>
      <div>&#x25bc;</div>
    </button>
  )
);
