// https://blog.logrocket.com/user-authentication-firebase-react-apps/
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Reset from './pages/Reset';
import Dashboard from './pages/Dashboard';
import PDF from './pages/PDF';
import BusinessPurposePDF from './pages/BusinessPurposePDF';
import BankingDisclosurePDF from './pages/BankingDisclosurePDF';
import BusinessLoanPDF from './pages/BusinessLoanPDF';
import AuthorizationPDF from './pages/AuthorizationPDF';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AllTemplates } from './pages/dashboard/forms/AllTemplates';
import Intro from './pages/dashboard/forms/Intro';

import { AboutYourSelf } from './pages/dashboard/forms/AboutYourSelf';
import { YourLocation } from './pages/dashboard/forms/YourLocation';
import { YourJobStatus } from './pages/dashboard/forms/YourJobStatus';
import { YourJobStatusDetail } from './pages/dashboard/forms/YourJobStatusDetail';
import { YourJobExpense } from './pages/dashboard/forms/YourJobExpense';
import { DoOwnSecurities } from './pages/dashboard/forms/DoOwnSecurities';

import { AboutYourCoAplicant } from './pages/dashboard/forms/AboutYourCoAplicant';
import { CoAplicantLocation } from './pages/dashboard/forms/CoAplicantLocation';
import { CoApplicantJobStatus } from './pages/dashboard/forms/CoApplicantJobStatus';

import { CashSavingFlow } from './pages/dashboard/forms/CashSavingFlow';
import { CashSaving } from './pages/dashboard/forms/CashSaving';
import { ShareBondFlow } from './pages/dashboard/forms/ShareBondFlow';
import { ShareBond } from './pages/dashboard/forms/ShareBond';

import { InsurancePolicyFlow } from './pages/dashboard/forms/InsurancePolicyFlow';
import { InsurancePolicy } from './pages/dashboard/forms/InsurancePolicy';

import { MortgageContractFlow } from './pages/dashboard/forms/MortgageContractFlow';
import { MortgageContract } from './pages/dashboard/forms/MortgageContract';

import { RealEstatePersonalFlow } from './pages/dashboard/forms/RealEstatePersonalFlow';
import { RealEstatePersonal } from './pages/dashboard/forms/RealEstatePersonal';

import { RealEstateInvestmentFlow } from './pages/dashboard/forms/RealEstateInvestmentFlow';
import { RealEstateInvestment } from './pages/dashboard/forms/RealEstateInvestment';

import { RealEstateSecondFlow } from './pages/dashboard/forms/RealEstateSecondFlow';
import { RealEstateSecond } from './pages/dashboard/forms/RealEstateSecond';

import { ProfitPensionFlow } from './pages/dashboard/forms/ProfitPensionFlow';
import { ProfitPension } from './pages/dashboard/forms/ProfitPension';

import { PayableLoanFlow } from './pages/dashboard/forms/PayableLoanFlow';
import { PayableLoan } from './pages/dashboard/forms/PayableLoan';

import { ContingentLiabilitiesFlow } from './pages/dashboard/forms/ContingentLiabilitiesFlow';
import { ContingentLiabilities } from './pages/dashboard/forms/ContingentLiabilities';
import { ContingentLiabilitiesQuestionnaire } from './pages/dashboard/forms/ContingentLiabilitiesQuestionnaire';
import { ContingentLiabilitiesQuestionnaireOther } from './pages/dashboard/forms/ContingentLiabilitiesQuestionnaireOther';

import { FinalStep } from './pages/dashboard/forms/FinalStep';
import { CompleteForm } from './pages/dashboard/forms/CompleteForm';
import { TransactionType } from './pages/dashboard/forms/TransactionType';

import DocusignAuth from './DocusignAuth';
import BussinessForm from './pages/dashboard/forms/BussinessForm';
import Property from './pages/dashboard/forms/Property';
import BussinessEntity from './pages/dashboard/forms/BussinessEntity';
import AdminPage from './pages/admin';

function App() {
	return (
		<div className='app'>
			<Routes>
				<Route exact path='/' element={<Login />} />
				<Route exact path='/react-oauth-docusign/build' element={<DocusignAuth />} />
				<Route exact path='/login' element={<Login />} />
				<Route exact path='/register' element={<Register />} />
				<Route exact path='/reset' element={<Reset />} />
				<Route exact path='/pdf' element={<PDF />} />
				<Route exact path='/admin' element={<AdminPage />} />
				<Route exact path='/BusinessPurposePDF' element={<BusinessPurposePDF />} />
				<Route exact path='/BankingDisclosurePDF' element={<BankingDisclosurePDF />} />
				<Route exact path='/BusinessLoanPDF' element={<BusinessLoanPDF />} />
				<Route exact path='/AuthorizationPDF' element={<AuthorizationPDF />} />
				<Route exact path='/dashboard' element={<Dashboard />}>
					<Route exact path='/dashboard' element={<AllTemplates />} />
					<Route exact path='/dashboard/intro' element={<Intro />} />
					<Route exact path='/dashboard/transactiontype' element={<TransactionType />} />
					<Route path='about-your-self' element={<AboutYourSelf />} />
					<Route path='your-location' element={<YourLocation />} />
					<Route path='your-job-status' element={<YourJobStatus />} />
					<Route path='your-job-status-detail' element={<YourJobStatusDetail />} />
					<Route path='your-job-expense' element={<YourJobExpense />} />
					<Route path='bussiness-entity' element={<BussinessEntity />} />
					<Route path='bussiness-form' element={<BussinessForm />} />
					{/* <Route path="property" element={<Property />} /> */}
					<Route path='share-bond-flow' element={<ShareBondFlow />} />
					<Route path='share-bond' element={<ShareBond />} />
					<Route path='about-your-co-Aplicant' element={<AboutYourCoAplicant />} />
					<Route path='co-aplicant-location' element={<CoAplicantLocation />} />
					<Route path='co-applicant-job-status' element={<CoApplicantJobStatus />} />
					<Route path='cash-saving-flow' element={<CashSavingFlow />} />
					<Route path='cash-saving' element={<CashSaving />} />
					<Route path='insurance-policy-flow' element={<InsurancePolicyFlow />} />
					<Route path='insurance-policy' element={<InsurancePolicy />} />
					<Route path='mortgage-contract-flow' element={<MortgageContractFlow />} />
					<Route path='mortgage-contract' element={<MortgageContract />} />
					<Route path='real-estate-personal-flow' element={<RealEstatePersonalFlow />} />
					<Route path='real-estate-personal' element={<RealEstatePersonal />} />
					<Route path='real-estate-investment-flow' element={<RealEstateInvestmentFlow />} />
					<Route path='real-estate-investment' element={<RealEstateInvestment />} />
					<Route path='real-estate-second-flow' element={<RealEstateSecondFlow />} />
					<Route path='real-estate-second' element={<RealEstateSecond />} />
					<Route path='profit-pension-flow' element={<ProfitPensionFlow />} />
					<Route path='profit-pension' element={<ProfitPension />} />
					<Route path='payable-loan-flow' element={<PayableLoanFlow />} />
					<Route path='payable-loan' element={<PayableLoan />} />
					<Route path='contingent-liabilities-flow' element={<ContingentLiabilitiesFlow />} />
					<Route path='contingent-liabilities' element={<ContingentLiabilities />} />
					<Route
						path='contingent-liabilities-questionnaire'
						element={<ContingentLiabilitiesQuestionnaire />}
					/>
					<Route
						path='contingent-liabilities-questionnaire-other'
						element={<ContingentLiabilitiesQuestionnaireOther />}
					/>
					{/* <Route path="final-step" element={<FinalStep />} /> */}
					<Route path='complete-form' element={<CompleteForm />} />
				</Route>
			</Routes>
		</div>
	);
}
export default App;
