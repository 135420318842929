import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddIcon from "../../../assets/img/add-template.png";
import AppIcon from "../../../assets/img/application-icon.png";
import Application from "./../Application";
import { Link } from "react-router-dom";

export const AllTemplates = () => {

  return (
    <Container>
      <Row className="py-5 my-3 g-4">
        <Application />
        <Col xs={3}>
          <Link to="/dashboard/intro">
            <div className="application-container">
              <div className="text-center">
                <img src={AddIcon} width="130px" />
                <h6 className="text-center font-bold">NEW APPLICATION</h6>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
