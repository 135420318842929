import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export const InsurancePolicyFlow = () => {
  return (
    <div className="body-bg py-5">
      <Container>
        <h2 className="step-head-gray pulse text-center py-3">Do you have Life Insurance?</h2>
        <Row className="py-5 justify-content-md-center">
          <Col xs={3}>
            <button className="cl-btn-blue big">
              <Link to="/dashboard/insurance-policy">Yes, I Do</Link>
            </button>
          </Col>
          <Col xs={3}>
            <button className="cl-btn-gray big">
              <Link to="/dashboard/mortgage-contract-flow">No, I don't</Link>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
