import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from "react-bootstrap/Form";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const YourJobExpense = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [FederalIncomeOtherTaxes, setFederalIncomeOtherTaxes] = useState('');
	const [StateIncomeOtherTaxes, setStateIncomeOtherTaxes] = useState('');
	const [RentalPaymentsCoopCondoMaintenance, setRentalPaymentsCoopCondoMaintenance] = useState('');
	const [MortgagePayments, setMortgagePayments] = useState('');
	const [PropertyTaxes, setPropertyTaxes] = useState('');
	const [ResidentialSchedule5, setResidentialSchedule5] = useState('');
	const [InvestmentSchedule5, setInvestmentSchedule5] = useState('');
	const [Residential, setResidential] = useState('');
	const [Investment, setInvestment] = useState('');
	const [InterestPrincipalPaymentsLoans, setInterestPrincipalPaymentsLoans] = useState('');
	const [InsuranceIncludingAutoHomeLifeDisabilityOther, setInsuranceIncludingAutoHomeLifeDisabilityOther] =
		useState('');
	const [InvestmentsIncludingTaxShelters, setInvestmentsIncludingTaxShelters] = useState('');
	const [AlimonyChildSupport, setAlimonyChildSupport] = useState('');
	const [Tuition, setTuition] = useState('');
	const [OtherLivingExpenses, setOtherLivingExpenses] = useState('');
	const [MedicalExpenses, setMedicalExpenses] = useState('');
	const [OtherExpenseList, setOtherExpenseList] = useState('');
	const [propertryTaxesResidential, setPropertryTaxesResidential] = useState('');
	const [propertryTaxesInvestment, setPropertryTaxesInvestment] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		const saveData = {
			FederalIncomeOtherTaxes: FederalIncomeOtherTaxes || 0,
			StateIncomeOtherTaxes: StateIncomeOtherTaxes || 0,
			RentalPaymentsCoopCondoMaintenance: RentalPaymentsCoopCondoMaintenance || 0,
			MortgagePayments: MortgagePayments || 0,
			PropertyTaxes: PropertyTaxes || 0,
			ResidentialSchedule5: ResidentialSchedule5 || 0,
			InvestmentSchedule5: InvestmentSchedule5 || 0,
			Residential: Residential || 0,
			Investment: Investment || 0,
			InterestPrincipalPaymentsLoans: InterestPrincipalPaymentsLoans || 0,
			InsuranceIncludingAutoHomeLifeDisabilityOther: InsuranceIncludingAutoHomeLifeDisabilityOther || 0,
			InvestmentsIncludingTaxShelters: InvestmentsIncludingTaxShelters || 0,
			AlimonyChildSupport: AlimonyChildSupport || 0,
			Tuition: Tuition || 0,
			OtherLivingExpenses: OtherLivingExpenses || 0,
			MedicalExpenses: MedicalExpenses || 0,
			OtherExpenseList: OtherExpenseList || 0,
			propertryTaxesResidential: propertryTaxesResidential || 0,
			propertryTaxesInvestment: propertryTaxesInvestment || 0,
		};
		await updateApplication(createApplicationDefault, { YourJobExpense: saveData });

		navigate('/dashboard/bussiness-entity');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setFederalIncomeOtherTaxes(getApplicationDefault?.YourJobExpense?.FederalIncomeOtherTaxes);
		setStateIncomeOtherTaxes(getApplicationDefault?.YourJobExpense?.StateIncomeOtherTaxes);
		setRentalPaymentsCoopCondoMaintenance(
			getApplicationDefault?.YourJobExpense?.RentalPaymentsCoopCondoMaintenance
		);
		setMortgagePayments(getApplicationDefault?.YourJobExpense?.MortgagePayments);
		setPropertyTaxes(getApplicationDefault?.YourJobExpense?.PropertyTaxes);
		setResidentialSchedule5(getApplicationDefault?.YourJobExpense?.ResidentialSchedule5);
		setInvestmentSchedule5(getApplicationDefault?.YourJobExpense?.InvestmentSchedule5);
		setResidential(getApplicationDefault?.YourJobExpense?.Residential);
		setInvestment(getApplicationDefault?.YourJobExpense?.Investment);
		setInterestPrincipalPaymentsLoans(getApplicationDefault?.YourJobExpense?.InterestPrincipalPaymentsLoans);
		setInsuranceIncludingAutoHomeLifeDisabilityOther(
			getApplicationDefault?.YourJobExpense?.InsuranceIncludingAutoHomeLifeDisabilityOther
		);
		setInvestmentsIncludingTaxShelters(getApplicationDefault?.YourJobExpense?.InvestmentsIncludingTaxShelters);
		setAlimonyChildSupport(getApplicationDefault?.YourJobExpense?.AlimonyChildSupport);
		setTuition(getApplicationDefault?.YourJobExpense?.Tuition);
		setOtherLivingExpenses(getApplicationDefault?.YourJobExpense?.OtherLivingExpenses);
		setMedicalExpenses(getApplicationDefault?.YourJobExpense?.MedicalExpenses);
		setOtherExpenseList(getApplicationDefault?.YourJobExpense?.OtherExpenseList);
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			?.replace(/\D/g, '')
			?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const numberFormatDesign = (e) => {
		let value = e.target.value;
		if (value) {
			value = value.replace(/\D/g, '');
			value = parseFloat(value);
			value = value.toLocaleString('en-US');
		} else {
			value = 0;
		}
		if (value === 'NaN') {
			value = 0;
		}
		return value;
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell us about your expenses?</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className='py-5'>
						<Col>
							<label>Federal Income and Other Taxes</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setFederalIncomeOtherTaxes(sanitizeValue(e.target.value))}
								value={parseCurrency(FederalIncomeOtherTaxes)}
							/>
						</Col>
						<Col>
							<label>State Income and Other Taxes?</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setStateIncomeOtherTaxes(sanitizeValue(e.target.value))}
								value={parseCurrency(StateIncomeOtherTaxes)}
							/>
						</Col>
						<Col>
							<label>Rental Payments, Co-op or Condo Maintenance</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setRentalPaymentsCoopCondoMaintenance(sanitizeValue(e.target.value))}
								value={parseCurrency(RentalPaymentsCoopCondoMaintenance)}
							/>
						</Col>
					</Row>

					<Row className='py-3 justify-content-md-center'>
						<Col xs={6}>
							<label>Property Taxes Residential</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setResidential(sanitizeValue(e.target.value))}
								value={parseCurrency(Residential)}
							/>
						</Col>
						<Col xs={6}>
							<label>Property Taxes Investment</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setInvestment(sanitizeValue(e.target.value))}
								value={parseCurrency(Investment)}
							/>
						</Col>
					</Row>

					<Row className='py-3 justify-content-md-center'>
						<Col xs={3}>
							<label>Interest & Principal Payments on Loans</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setInterestPrincipalPaymentsLoans(sanitizeValue(e.target.value))}
								value={parseCurrency(InterestPrincipalPaymentsLoans)}
							/>
						</Col>
						<Col xs={3}>
							<label>Insurance (including Auto / Home / Life / Disability / Other)</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) =>
									setInsuranceIncludingAutoHomeLifeDisabilityOther(sanitizeValue(e.target.value))
								}
								value={parseCurrency(InsuranceIncludingAutoHomeLifeDisabilityOther)}
							/>
						</Col>
						<Col xs={3}>
							<label>Investments (Including tax shelters)</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setInvestmentsIncludingTaxShelters(sanitizeValue(e.target.value))}
								value={parseCurrency(InvestmentsIncludingTaxShelters)}
							/>
						</Col>
						<Col xs={3}>
							<label>Alimony / Child Support</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setAlimonyChildSupport(sanitizeValue(e.target.value))}
								value={parseCurrency(AlimonyChildSupport)}
							/>
						</Col>
					</Row>

					<Row className='py-3 justify-content-md-center'>
						<Col xs={3}>
							<label>Tuition</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setTuition(sanitizeValue(e.target.value))}
								value={parseCurrency(Tuition)}
							/>
						</Col>
						<Col xs={3}>
							<label>Other Living Expenses</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setOtherLivingExpenses(sanitizeValue(e.target.value))}
								value={parseCurrency(OtherLivingExpenses)}
							/>
						</Col>
						<Col xs={3}>
							<label>Medical Expenses</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setMedicalExpenses(sanitizeValue(e.target.value))}
								value={parseCurrency(MedicalExpenses)}
							/>
						</Col>
						<Col xs={3}>
							<label>Other Expense (List)</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setOtherExpenseList(sanitizeValue(e.target.value))}
								value={parseCurrency(OtherExpenseList)}
							/>
						</Col>
					</Row>

					<Row className='py-3 justify-content-md-center'>
						<Col xs={3}>
							<label>Property taxes residential (if not included in the payment)</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setPropertryTaxesResidential(sanitizeValue(e.target.value))}
								value={parseCurrency(propertryTaxesResidential)}
							/>
						</Col>
						<Col xs={3}>
							<label>Property Taxes Investment (if not included in the payment)</label>
							<input
								type='text'
								placeholder='0'
								onChange={(e) => setPropertryTaxesInvestment(sanitizeValue(e.target.value))}
								value={parseCurrency(propertryTaxesInvestment)}
							/>
						</Col>
					</Row>

					<Row className='py-5 justify-content-between'>
						<Col xs={5} md={3}>
							<Link to='/dashboard/your-job-status-detail' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
