import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { getApplication, updateApplication } from "./../../../firebase";

function Property() {
    const navigate = useNavigate();

    const [form, setForm] = useState({ propertyStatus: false, propertyAddress: '' })
    const [errors, setErrors] = useState({ propertyStatus: '', propertyAddress: '' })

    const createApplicationDefault = localStorage.getItem("createApplicationDefault");

    const onSubmit = async (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (form.propertyStatus && form.propertyAddress == "") return setErrors({ propertyAddress: "Property Address is required" })
        const saveData = form.propertyStatus ? form.propertyAddress : 'TBD';

        await updateApplication(createApplicationDefault, { PropertyAddress: saveData });

        navigate("/dashboard/share-bond-flow");
    };


    const fetchPost = async () => {
        const getApplicationDefault = await getApplication(createApplicationDefault);
        console.log({ getApplicationDefault });
        if (getApplicationDefault.PropertyAddress !== 'TBD')
            setForm({ propertyStatus: true, propertyAddress: getApplicationDefault.PropertyAddress });
    };

    useEffect(() => { fetchPost() }, []);

    return (
        <div className="body-bg py-5">
            <Container>
                <h2 className="step-head-blue pulse text-center py-3">Property Status?</h2>
                <form onSubmit={onSubmit}>
                    <Row className="py-5">
                        <Col>
                            <label className="form-check-label" htmlFor="propertyStatus">Do you own any property</label>
                            <input className="form-check-input mx-5" type="checkbox" role="switch" id="propertyStatus" name="propertyStatus" checked={form.propertyStatus} onChange={() => setForm({ ...form, propertyStatus: !form.propertyStatus })} />
                        </Col>
                    </Row>

                    {form.propertyStatus &&
                        <Row className="py-3 justify-content-md-center">
                            <Col>
                                <label>Property Address</label>
                                <input type="text" onChange={(e) => setForm({ ...form, propertyAddress: e.target.value })} name="propertyAddress" value={form.propertyAddress} />
                                {errors.propertyAddress && (<span>Property Address is required</span>)}
                            </Col>
                        </Row>
                    }

                    <Row className="py-5 justify-content-between">
                        <Col xs={5} md={3}>
                            <Link to="/dashboard/bussiness-form" className="cl-btn position-absolute"> Back </Link>
                        </Col>
                        <Col xs={5} md={3}>
                            <input className="cl-btn" type="submit" value="Save & Continue" />
                        </Col>
                    </Row>
                </form>
            </Container>
        </div>
    );
}

export default Property