import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from "react-bootstrap/Alert";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getApplication, updateApplication } from "./../../../firebase";

export const TransactionType = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [transactionType, setTransactionType] = useState("");
  const [transactionTypeApply, setTransactionTypeApply] = useState("");
  const [show, setShow] = useState(false);
  const [warning, setWarning] = useState('');

  const createApplicationDefault = localStorage.getItem("createApplicationDefault");

  const onSubmit = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log({ data })

    // console.log("onSubmit data", data);
    if (transactionType == "") {
      // setWarning("Please select Transaction Type");
      // setShow(true);
      return false;
    } else if (transactionTypeApply == "") {
      setWarning("Please select Are you applying with anyone else?");
      setShow(true);
      return false;
    } else if (transactionTypeApply == "false") {
      setWarning("Please ask you non-spouse to fill out the separate form");
      setShow(true);
      return false;
    }

    // console.log("updateApplication id, data", createApplicationDefault, data);


    updateApplication(createApplicationDefault, { TransactionType: { TransactionType: transactionType, TransactionTypeApply: transactionTypeApply } });
    // Firebase Store, Create new Record and Store Data

    navigate("/dashboard/about-your-self");
  };

  const fetchPost = async () => {
    console.log("createApplicationDefault", createApplicationDefault);
    const getApplicationDefault = await getApplication(createApplicationDefault);
    console.log({ getApplicationDefault })
    setTransactionType(getApplicationDefault.TransactionType.TransactionType);
    setTransactionTypeApply(getApplicationDefault.TransactionType.TransactionTypeApply);
  };

  useEffect(() => {
    fetchPost();
  }, []);

  // console.log("transactionType", transactionType);
  // console.log("transactionTypeApply", transactionTypeApply);
  // console.log("watch register", watch(register));
  // console.log("errors", errors);

  return (
    <>
      <div className="body-bg py-3">
        <Container>
          <h2 className="step-head-blue pulse text-center py-3">What type of transaction are you doing?</h2>
          <p className="text-center py-3">Help us understand the type of transaction you want to execute.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="py-3 justify-content-md-center">
              <Col xs={3}>
                <label>Transaction Type</label>
                <select className="form-select" aria-label="Default select example" {...register(".TransactionType", { required: true })} onChange={(e) => setTransactionType(e.target.value)} value={transactionType}>
                  <option value="">Transaction Type</option>
                  <option value="purchase">Purchase</option>
                  <option value="refinance">Refinance</option>
                  <option value="cash-out">Cash-Out Refinance</option>
                  <option value="LOC">Business Line of Credit (LOC)</option>
                  <option value="non-real-estate">Non-Real Estate Business Loan</option>
                </select>
                {errors && errors.TransactionType && (
                  <span>Transaction Type is required*</span>
                )}
              </Col>
              <Col xs={3}>
                <label>Are you applying with anyone else?</label>
                {/* <select className="form-select" aria-label="Default select example" {...register(".TransactionTypeApply", { required: true })} onChange={(e) => setTransactionTypeApply(e.target.value)} value={transactionTypeApply}> */}
                <select className="form-select" aria-label="Default select example" name="TransactionTypeApply" onChange={(e) => setTransactionTypeApply(e.target.value)} value={transactionTypeApply}>
                  <option value="" disabled hidden> Please Select </option>
                  <option value="no">I'm Applying Alone</option>
                  <option value="yes">Appling with Spouse</option>
                  <option value="false">Applying with Non-Spouse</option>
                </select>
                {/* {errors && errors.TransactionTypeApply && setShow(true)} */}
                {/* <Alert variant="warning">Please ask you non-spouse to fill out the separate form</Alert> */}
                {/* )} */}
              </Col>
            </Row>

            <Row className="py-5 justify-content-md-center">
              <Col xs={3} className="text-center">
                <input className="cl-btn" type="submit" value="Save & Continue" />
              </Col>
            </Row>
          </form>
        </Container>
      </div>
      <Warning show={show} setShow={setShow} text={warning} setText={setWarning} />

    </>
  );
};



const Warning = ({ show, setShow, text, setText }) => {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setText('');
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {text}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

