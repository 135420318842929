import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from "react-bootstrap/Form";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const RealEstateSecond = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [LegalOwner, setLegalOwner] = useState('');
	const [PropertyAddress, setPropertyAddress] = useState('');
	const [PurchasePrice, setPurchasePrice] = useState('');
	const [PurchaseYear, setPurchaseYear] = useState('');
	const [MarketValue, setMarketValue] = useState('');
	const [PresentLoanBal, setPresentLoanBal] = useState('');
	const [InterestRate, setInterestRate] = useState('');
	const [LoanMaturityDate, setLoanMaturityDate] = useState('');
	const [MonthlyRentalIncome, setMonthlyRentalIncome] = useState('');
	const [MonthlyPayment, setMonthlyPayment] = useState('');
	const [Lender, setLender] = useState('');

	const [fullNameAYS, setFullNameAYS] = useState('');
	const [StreetAddress, setStreetAddress] = useState('');

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		const saveData = {
			LegalOwner,
			PropertyAddress,
			PurchasePrice,
			PurchaseYear,
			MarketValue,
			PresentLoanBal,
			InterestRate,
			LoanMaturityDate,
			MonthlyRentalIncome,
			MonthlyPayment,
			Lender,
		};
		await updateApplication(createApplicationDefault, { RealEstateSecond: saveData });

		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/profit-pension-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });

		setLegalOwner(getApplicationDefault.RealEstateSecond.LegalOwner);
		setPropertyAddress(getApplicationDefault.RealEstateSecond.PropertyAddress);
		setPurchasePrice(getApplicationDefault.RealEstateSecond.PurchasePrice);
		setPurchaseYear(getApplicationDefault.RealEstateSecond.PurchaseYear);
		setMarketValue(getApplicationDefault.RealEstateSecond.MarketValue);
		setPresentLoanBal(getApplicationDefault.RealEstateSecond.PresentLoanBal);
		setInterestRate(getApplicationDefault.RealEstateSecond.InterestRate);
		setLoanMaturityDate(getApplicationDefault.RealEstateSecond.LoanMaturityDate);
		setMonthlyRentalIncome(getApplicationDefault.RealEstateSecond.MonthlyRentalIncome);
		setMonthlyPayment(getApplicationDefault.RealEstateSecond.MonthlyPayment);
		setLender(getApplicationDefault.RealEstateSecond.Lender);

		// setFullNameAYS(getApplicationDefault.AboutYourSelf.FullName);
		// setStreetAddress(getApplicationDefault.YourLocation.StreetAddress);
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	if (fullNameAYS && StreetAddress) {
		if (LegalOwner === '') {
			setLegalOwner(fullNameAYS);
		}
		if (LegalOwner === '') {
			setPropertyAddress(StreetAddress);
		}
	}

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell me about your second home</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className='py-3'>
						<Col xs={3}>
							<label>Legal Owner</label>
							<input
								type='text'
								{...register('.LegalOwner')}
								onChange={(e) => setLegalOwner(e.target.value)}
								value={LegalOwner}
							/>
							{errors && errors.LegalOwner && <span>Legal Owner is required*</span>}
						</Col>
						<Col xs={6}>
							<label>Property Address</label>
							<input
								type='text'
								{...register('.PropertyAddress')}
								onChange={(e) => setPropertyAddress(e.target.value)}
								value={PropertyAddress}
							/>
							{errors && errors.PropertyAddress && <span>Property Address is required*</span>}
						</Col>
						<Col>
							<label>Purchase Price</label>
							<input
								type='text'
								{...register('.PurchasePrice')}
								onChange={(e) => setPurchasePrice(sanitizeValue(e.target.value))}
								value={parseCurrency(PurchasePrice)}
							/>
							{errors && errors.PurchasePrice && <span>Purchase Price is required*</span>}
						</Col>
						<Col>
							<label>Purchase Year</label>
							<input
								type='text'
								{...register('.PurchaseYear')}
								onChange={(e) => setPurchaseYear(e.target.value)}
								value={PurchaseYear}
								maxlength='2'
							/>
							{errors && errors.PurchaseYear && <span>Purchase Year is required*</span>}
						</Col>
					</Row>
					<Row className='py-3'>
						<Col>
							<label>Market Value</label>
							<input
								type='text'
								{...register('.MarketValue')}
								onChange={(e) => setMarketValue(sanitizeValue(e.target.value))}
								value={parseCurrency(MarketValue)}
							/>
							{errors && errors.MarketValue && <span>Market Value is required*</span>}
						</Col>
						<Col>
							<label>Present Loan Bal. $</label>
							<input
								type='text'
								{...register('.PresentLoanBal')}
								onChange={(e) => setPresentLoanBal(sanitizeValue(e.target.value))}
								value={parseCurrency(PresentLoanBal)}
							/>
							{errors && errors.PresentLoanBal && <span>Present Loan Bal is required*</span>}
						</Col>
						<Col>
							<label>Interest Rate</label>
							<input
								type='text'
								{...register('.InterestRate')}
								onChange={(e) => setInterestRate(e.target.value)}
								value={InterestRate}
							/>
							{errors && errors.InterestRate && <span>Interest Rate is required*</span>}
						</Col>
						<Col>
							<label>Loan Maturity Date</label>
							<input
								type='text'
								{...register('.LoanMaturityDate')}
								onChange={(e) => setLoanMaturityDate(e.target.value)}
								value={LoanMaturityDate}
							/>
							{errors && errors.LoanMaturityDate && <span>Loan Maturity Date is required*</span>}
						</Col>
					</Row>
					<Row className='py-3 justify-content-md-center'>
						<Col xs={3}>
							<label>Monthly Rental Income</label>
							<input
								type='text'
								{...register('.MonthlyRentalIncome')}
								onChange={(e) => setMonthlyRentalIncome(e.target.value)}
								value={MonthlyRentalIncome}
							/>
							{errors && errors.MonthlyRentalIncome && <span>Monthly Rental Income is required*</span>}
						</Col>
						<Col xs={3}>
							<label xs={3}>Monthly Payment(s)</label>
							<input
								type='text'
								{...register('.MonthlyPayment')}
								onChange={(e) => setMonthlyPayment(sanitizeValue(e.target.value))}
								value={parseCurrency(MonthlyPayment)}
							/>
							{errors && errors.MonthlyPayment && <span>Monthly Payment is required*</span>}
						</Col>
						<Col xs={3}>
							<label>Lender</label>
							<input
								type='text'
								{...register('.Lender')}
								onChange={(e) => setLender(e.target.value)}
								value={Lender}
							/>
							{errors && errors.Lender && <span>Lender is required*</span>}
						</Col>
					</Row>
					{/* 
          <Row>
            <Col className="text-center py-5">
              <Link to="/dashboard/real-estate-second-flow" className="btn btn-outline-success btn-height px-5 mx-5">Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}

					<Row className='py-5 justify-content-between'>
						<Col xs={5} md={3}>
							<Link to='/dashboard/real-estate-second-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
