import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getApplication, updateApplication } from "./../../../firebase";

export const ContingentLiabilities = () => {

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [arr, setArr] = useState([
    /*
    {
      group: "",
      ToWhomPayable: "",
      Terms: "",
      MaximumLiability: "",
      co_group: "",
      CoAppToWhomPayable: "",
      CoAppTerms: "",
      CoAppMaximumLiability: "",
    }
    */
  ]);

  const createApplicationDefault = localStorage.getItem("createApplicationDefault");

  const onSubmit = async (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    console.log({ data });

    await updateApplication(createApplicationDefault, { ContingentLiabilities: data });
    // Firebase Store, Create new Record and Store Data

    navigate("/dashboard/contingent-liabilities-questionnaire");
  };


  const fetchPost = async () => {
    const getApplicationDefault = await getApplication(
      createApplicationDefault
    );
    console.log({ getApplicationDefault });
    setArr(Object.values(getApplicationDefault.ContingentLiabilities));
  };

  useEffect(() => { fetchPost(); }, []);

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        [
          {
            group: "",
            ToWhomPayable: "",
            Terms: "",
            MaximumLiability: "",
            co_group: "",
            CoAppToWhomPayable: "",
            CoAppTerms: "",
            CoAppMaximumLiability: "",
          },
        ],
      ];
    });
  };

  const updateInput = (evnt, index, ele) => {
    const { value } = evnt.target;
    const list = [...arr];
    list[index][ele] = value;
    setArr(list);
  }


  return (
    <div className="body-bg py-5">
      <Container>
        <h2 className="step-head-blue pulse text-center py-3">Contingent Liabilities</h2>
        <h4 className="font-bold primary py-5 text-center">Are you a co-maker, endorser or guarantor on any debts, leases or other obligations?</h4>
        <form onSubmit={handleSubmit(onSubmit)}>{arr.map((item, i) => {
          return (<Row key={"ContingentLiabilities_" + i}>
            <Row className="py-3 justify-content-md-center ">
              <Col className="text-center shedule8-container">
                <h5 className="font-bold">Applicant Detail</h5>
                {["radio"].map((type, index) => (
                  <div key={`group_${i}_${index}`} className="mb-3 py-3">
                    <Form.Check
                      inline
                      label="Yes"
                      value="Yes"
                      type={type}
                      id={`group_${i}_${index}`}
                      {...register(i + ".group")}
                      defaultChecked={(item.group === "Yes")}
                      onChange={(e) => updateInput(e, i, "group")}
                    />
                    <Form.Check
                      inline
                      label="No"
                      value="No"
                      type={type}
                      id={`group_${i}_${index}`}
                      {...register(i + ".group")}
                      defaultChecked={(item.group === "No")}
                      onChange={(e) => updateInput(e, i, "group")}
                    />
                  </div>
                ))}
                {(item.group === "Yes") ?
                  <>
                    <Row className="py-3 justify-content-md-center">
                      <Col className="text-center">
                        <label>To Whom Payable</label>
                        <input
                          type="text"
                          {...register(i + ".ToWhomPayable")}
                          value={item.ToWhomPayable}
                          onChange={(e) => updateInput(e, i, "ToWhomPayable")}
                        />
                      </Col>
                      <Col className="text-center">
                        <label>Terms</label>
                        <input
                          type="text"
                          {...register(i + ".Terms")}
                          value={item.Terms}
                          onChange={(e) => updateInput(e, i, "Terms")}
                        />
                      </Col>
                    </Row>
                    <Row className="py-3 justify-content-md-center">
                      <Col className="text-center">
                        <label>Maximum Liability</label>
                        <input
                          type="text"
                          {...register(i + ".MaximumLiability")}
                          value={item.MaximumLiability}
                          onChange={(e) => updateInput(e, i, "MaximumLiability")}
                        />
                      </Col>
                    </Row>
                  </>
                  : ""}
              </Col>

              <Col className="text-center shedule8-container">
                <h5 className="font-bold">Co-Applicant Detail</h5>
                {["radio"].map((type, index) => (
                  <div key={`co_group_${i}_${index} `} className="mb-3 py-3">
                    <Form.Check
                      inline
                      label="Yes"
                      value="Yes"
                      type={type}
                      id={`co_group_${i}_${index}`}
                      {...register(i + ".co_group")}
                      defaultChecked={(item.co_group === "Yes")}
                      onChange={(e) => updateInput(e, i, "co_group")}
                    />
                    <Form.Check
                      inline
                      label="No"
                      value="No"
                      type={type}
                      id={`co_group_${i}_${index}`}
                      {...register(i + ".co_group")}
                      defaultChecked={(item.co_group === "No")}
                      onChange={(e) => updateInput(e, i, "co_group")}
                    />
                  </div>
                ))}
                {(item.co_group === "Yes") ?
                  <>
                    <Row className="py-3 justify-content-md-center">
                      <Col className="text-center">
                        <label>To Whom Payable</label>
                        <input
                          type="text"
                          {...register(i + ".CoAppToWhomPayable")}
                          value={item.CoAppToWhomPayable}
                          onChange={(e) => updateInput(e, i, "CoAppToWhomPayable")}
                        />
                      </Col>
                      <Col className="text-center">
                        <label>Terms</label>
                        <input
                          type="text"
                          {...register(i + ".CoAppTerms")}
                          value={item.CoAppTerms}
                          onChange={(e) => updateInput(e, i, "CoAppTerms")}
                        />
                      </Col>
                    </Row>
                    <Row className="py-3 justify-content-md-center">
                      <Col className="text-center">
                        <label>Maximum Liability</label>
                        <input
                          type="text"
                          {...register(i + ".CoAppMaximumLiability")}
                          value={item.CoAppMaximumLiability}
                          onChange={(e) => updateInput(e, i, "CoAppMaximumLiability")}
                        />
                      </Col>
                    </Row>
                  </>
                  : ""}
              </Col>
            </Row>
          </Row>);
        })}

          <Row className="py-5 justify-content-md-center">
            <Col xs={3} className="text-center ">
              <div onClick={addInput} className="cl-btn">+</div>
            </Col>
          </Row>

          {/* <Row>
            <Col className="py-4 text-center">
              <Link to="/dashboard/contingent-liabilities-flow" className="cl-btn">Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}

          <Row className="py-5 justify-content-between">
            <Col xs={5} md={3}>
              <Link to="/dashboard/contingent-liabilities-flow" className="cl-btn position-absolute"> Back </Link>
            </Col>
            <Col xs={5} md={3}>
              <input className="cl-btn" type="submit" value="Save & Continue" />
            </Col>
          </Row>

        </form>
      </Container>
    </div>
  );
};
