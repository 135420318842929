import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const PayableLoan = () => {
	const {
		register,
		handleSubmit,
		// watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data });
		// Convert arr to the format of data. Using arr here becuase data is raw and arr is sanitized
		// arr is sanitized in updateInput function
		const saveData = {};
		arr.map((item, i) => {
			saveData[i] = item;
		});
		console.log({ saveData });

		updateApplication(createApplicationDefault, { PayableLoan: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/contingent-liabilities-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.PayableLoan));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addInput = () => {
		setArr((s) => {
			return [
				...s,
				[
					{
						ToWhomPayable: '',
						Address: '',
						InterestRate: '',
						CollateralIndicateUnsecured: '',
						MonthlyPayment: '',
						MaturityDate: '',
						UnpaidBalance: '',
					},
				],
			];
		});
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			?.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const updateInput = (evnt, index, ele) => {
		const { value } = evnt.target;
		const list = [...arr];
		if (ele === 'MonthlyPayment' || ele === 'UnpaidBalance') list[index][ele] = sanitizeValue(value);
		else list[index][ele] = value;
		setArr(list);
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Provide list of Payable Loan by Banks?</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row>
								<Row className='py-3'>
									<Col xs={4}>
										<label>To Whom Payable</label>
										<input
											value={item.ToWhomPayable}
											type='text'
											{...register(i + '.ToWhomPayable')}
											onChange={(e) => updateInput(e, i, 'ToWhomPayable')}
										/>
										{errors[i] && errors[i].ToWhomPayable && (
											<span>To Whom Payable is required*</span>
										)}
									</Col>
									<Col xs={4}>
										<label>Address</label>
										<input
											value={item.Address}
											type='text'
											{...register(i + '.Address')}
											onChange={(e) => updateInput(e, i, 'Address')}
										/>
										{errors[i] && errors[i].Address && <span>Address is required*</span>}
									</Col>
									<Col xs={4}>
										<label>Interest Rate</label>
										<input
											value={item.InterestRate}
											type='text'
											{...register(i + '.InterestRate')}
											onChange={(e) => updateInput(e, i, 'InterestRate')}
										/>
										{errors[i] && errors[i].InterestRate && <span>Interest Rate is required*</span>}
									</Col>
								</Row>
								<Row className='py-3 justify-content-md-center'>
									<Col xs={10}>
										<label>Describe Collateral or indicate if Unsecured</label>
										{/* <select {...register(i + ".CollateralIndicateUnsecured")} onChange={(e) => updateInput(e, i, "CollateralIndicateUnsecured")} value={item.CollateralIndicateUnsecured}>
                  <option value="Describe Collateral">Describe Collateral</option>
                  <option value="indicate if Unsecured">indicate if Unsecured</option>
                </select> */}

										<input
											value={item.CollateralIndicateUnsecured}
											type='text'
											{...register(i + '.CollateralIndicateUnsecured')}
											onChange={(e) => updateInput(e, i, 'CollateralIndicateUnsecured')}
										/>

										{errors[i] && errors[i].CollateralIndicateUnsecured && (
											<span>Describe Collateral or indicate if Unsecured is required*</span>
										)}
									</Col>
								</Row>
								<Row className='py-3 justify-content-md-center'>
									<Col xs={4}>
										<label>Monthly payment ($)</label>
										<input
											value={parseCurrency(item.MonthlyPayment)}
											type='text'
											{...register(i + '.MonthlyPayment')}
											onChange={(e) => updateInput(e, i, 'MonthlyPayment')}
										/>
										{errors[i] && errors[i].MonthlyPayment && (
											<span>Monthly payment is required*</span>
										)}
									</Col>

									<Col>
										<label>Maturity Date</label>
										<input
											value={item.MaturityDate}
											type='date'
											{...register(i + '.MaturityDate')}
											onChange={(e) => updateInput(e, i, 'MaturityDate')}
										/>
										{errors[i] && errors[i].MaturityDate && <span>Maturity Date is required*</span>}
									</Col>
									<Col xs={4}>
										<label>Unpaid Balance ($)</label>
										<input
											value={parseCurrency(item.UnpaidBalance)}
											type='text'
											{...register(i + '.UnpaidBalance')}
											onChange={(e) => updateInput(e, i, 'UnpaidBalance')}
										/>
										{errors[i] && errors[i].UnpaidBalance && (
											<span>Unpaid Balance is required*</span>
										)}
									</Col>
								</Row>
							</Row>
						);
					})}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/* <Row>
            <Col className="text-center py-5">
              <Link to="/dashboard/payable-loan-flow" className="btn-outline-primary mr-2">Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}
					<Row className='py-5 justify-content-between'>
						<Col xs={5} md={3}>
							<Link to='/dashboard/payable-loan-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
