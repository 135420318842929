import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link, useNavigate } from "react-router-dom";
import { createApplicationDefault } from "./../../../firebase";

import { AboutYourSelf } from "./AboutYourSelf";
import { TransactionType } from "./TransactionType";
import { YourLocation } from "./YourLocation";

const Intro = () => {
  // const [form, setForm] = useState({});
  // const clearForm = () => {
  //   setForm({});
  // };
  // const handleChange = (e, formattedValue = false) => {
  //   if (!formattedValue) {
  //     setForm({ ...form, [e.target.name]: e.target.value });
  //     return;
  //   }
  //   setForm({ ...form, [e.target.name]: formattedValue });
  // };

  // const updateDependentAge = (index, age) => {
  //   const newDependents = [...form.Dependents];
  //   newDependents[index] = age;
  //   setForm({ ...form, Dependents: newDependents });
  // };

  const navigate = useNavigate();

  const GetStartedNowBtn = async () => {
    const docId = await createApplicationDefault();
    navigate("/dashboard/transactiontype", { state: { docId } });
    // navigate("/dashboard/transactiontype");
    // navigate("/dashboard/transactiontype", { state: { form:form, handleChange, updateDependentAge } });
  };

  return (
    <div className="body-bg">
      <Container className="py-5 my-5">
        <h1 className="font-bold text-center font-light py-5">Let's get started on your loan process</h1>
        <p className="text-center font-light">Let's start by gathering some basic information about you, your job and your income, any partners you have and the type of transaction you plan to execute.</p>
        <div className="text-center py-3">
          <button className="text-center cl-btn" onClick={GetStartedNowBtn}>
            GET STARTED NOW
          </button>
        </div>
      </Container>
    </div>
  );
};


// const Intro = () => {
//   const [stage, setStage] = useState(0)
//   const [form, setForm] = useState({});
//   const clearForm = () => {
//     setForm({});
//   };
//   const handleChange = (e, formattedValue = false) => {
//     if (!formattedValue) {
//       setForm({ ...form, [e.target.name]: e.target.value });
//       return;
//     }
//     setForm({ ...form, [e.target.name]: formattedValue });
//   };

//   const updateDependentAge = (index, age) => {
//     const newDependents = [...form.Dependents];
//     newDependents[index] = age;
//     setForm({ ...form, Dependents: newDependents });
//   };


//   return (
//     <div className="body-bg">
//       <RenderForm form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} stage={stage} setStage={setStage} />
//     </div>
//   )

// }

// const Home = () => {
//   return (
//     <Container className="py-5 my-5">
//       <h1 className="font-bold text-center font-light py-5">Let's get started on your loan process</h1>
//       <p className="text-center font-light">Let's start by gathering some basic information about you, your job and your income, any partners you have and they type of transaction you plan to execute.</p>
//       <div className="text-center py-3">
//         <button className="text-center cl-btn" onClick={GetStartedNowBtn}>
//           GET STARTED NOW
//         </button>
//       </div>
//     </Container>
//   );
// }

// const RenderForm = ({ form, handleChange, updateDependentAge, stage, setStage }) => {
//   switch (stage) {
//     case 0:
//       return <Home setStage={setStage} />;
//       break;
//     case 1:
//       return <TransactionType form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 2:
//       return <AboutYourSelf form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 3:
//       return <YourLocation form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 4:
//       return <YourJobStatus form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 5:
//       return <YourJobStatusDetail form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 6:
//       return <YourJobExpense form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 7:
//       return <ShareBondFlow form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 8:
//       return <ShareBond form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 9:
//       return <AboutYourCoAplicant form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 10:
//       return <CoAplicantLocation form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 11:
//       return <CoApplicantJobStatus form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 12:
//       return <CashSavingFlow form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 13:
//       return <CashSaving form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 14:
//       return <InsurancePolicyFlow form={form} handleChange={handleChange} updateDependentAge={updateDependentAge} setStage={setStage} />
//       break;
//     case 15:

//     default:
//       return (
//         <div>
//           <h1>Step 1</h1>
//         </div>
//       );

//   }
// }

export default Intro