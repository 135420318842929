import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getApplication, updateApplication } from './../../../firebase';

export const MortgageContract = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();

	const [arr, setArr] = useState([
		{
			// NameDebtor: "",
			// DescriptionProperty: "",
			// FirstSecondLien: "",
			// DateMaturity: "",
			// RepaymentTerms: "",
			// BalanceDue: "",
		},
	]);

	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (data) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		console.log({ data, arr });
		await updateApplication(createApplicationDefault, { MortgageContract: arr });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/real-estate-personal-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		setArr(Object.values(getApplicationDefault.MortgageContract));
	};

	useEffect(() => {
		fetchPost();
	}, []);

	const addInput = () => {
		setArr((prevArr) => [
			...prevArr,
			{
				NameDebtor: '',
				DescriptionProperty: '',
				FirstSecondLien: '',
				DateMaturity: '',
				RepaymentTerms: '',
				BalanceDue: '',
			},
		]);
	};

	const parseCurrency = (value) => {
		// Regex for removing leading zeros and adding commas
		return value
			?.toString()
			.replace(/^0+/, '')
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	// Sanitize value to allow only numbers in the data base
	const sanitizeValue = (value) => {
		return parseInt(value?.toString().replace(/\D/g, '').replace(/,/g, '')) || 0;
	};

	const updateInput = (evnt, index, ele) => {
		const { value } = evnt.target;
		const updatedArr = arr.map((item, i) => {
			if (i === index) {
				if (ele === 'BalanceDue')
					return {
						...item,
						[ele]: sanitizeValue(value),
					};
				else
					return {
						...item,
						[ele]: value,
					};
			}
			return item;
		});
		setArr(updatedArr);
	};

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>
					Information About Mortage, Contract & Receivables
				</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					{arr.map((item, i) => {
						return (
							<Row key={i}>
								<Row className='py-5'>
									<Col>
										<label>Name of Debtor</label>
										<input
											value={item.NameDebtor}
											type='text'
											{...register(i + '.NameDebtor')}
											onChange={(e) => updateInput(e, i, 'NameDebtor')}
										/>
										{errors[i] && errors[i].NameDebtor && <span>Name of Debtor is required*</span>}
									</Col>
									<Col xs={6}>
										<label>Description of Property</label>
										<input
											value={item.DescriptionProperty}
											type='text'
											{...register(i + '.DescriptionProperty')}
											onChange={(e) => updateInput(e, i, 'DescriptionProperty')}
										/>
										{errors[i] && errors[i].DescriptionProperty && (
											<span>Description of Property is required*</span>
										)}
									</Col>
									<Col>
										<label>First Lien or Second Lien</label>
										<select
											{...register(i + '.FirstSecondLien')}
											onChange={(e) => updateInput(e, i, 'FirstSecondLien')}
											value={item.FirstSecondLien}
										>
											<option value='First Lien'>First Lien</option>
											<option value='Second Lien'>Second Lien</option>
										</select>
										{/*
                  <input
                    value={item.FirstSecondLien}
                    type="text"
                    {...register(i + ".FirstSecondLien")}
                    onChange={(e) => updateInput(e, i, "FirstSecondLien")}
                  />
                  */}
										{errors[i] && errors[i].FirstSecondLien && (
											<span>First Lien or Second Lien is required*</span>
										)}
									</Col>
								</Row>
								<Row className='mb justify-content-md-center'>
									<Col xs={3}>
										<label>Date of Maturity</label>
										<input
											value={item.DateMaturity}
											type='date'
											{...register(i + '.DateMaturity')}
											onChange={(e) => updateInput(e, i, 'DateMaturity')}
										/>
										{errors[i] && errors[i].DateMaturity && (
											<span>Date of Maturity is required*</span>
										)}
									</Col>
									<Col xs={3}>
										<label>Repayment Terms</label>
										<input
											value={item.RepaymentTerms}
											type='text'
											placeholder='Ex: Per Month'
											{...register(i + '.RepaymentTerms')}
											onChange={(e) => updateInput(e, i, 'RepaymentTerms')}
										/>
										{errors[i] && errors[i].RepaymentTerms && (
											<span>Repayment Terms is required*</span>
										)}
									</Col>
									<Col xs={3}>
										<label>Balance Due ($)</label>
										<input
											value={parseCurrency(item.BalanceDue)}
											type='text'
											{...register(i + '.BalanceDue')}
											onChange={(e) => updateInput(e, i, 'BalanceDue')}
											placeholder='0'
										/>
										{errors[i] && errors[i].BalanceDue && <span>Balance Due is required*</span>}
									</Col>
								</Row>
							</Row>
						);
					})}

					<Row className='py-5 justify-content-md-center'>
						<Col xs={3} className='text-center '>
							<div onClick={addInput} className='cl-btn'>
								+
							</div>
						</Col>
					</Row>

					{/* <Row>
            <Col className="text-center">
              <Link to="/dashboard/mortgage-contract-flow" className="btn-outline-primary mr-2">Back</Link>
              <button className="cl-btn">Save & Continue</button>
            </Col>
          </Row> */}
					<Row className='py-5 justify-content-between'>
						<Col xs={5} md={3}>
							<Link to='/dashboard/mortgage-contract-flow' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
};
