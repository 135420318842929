import React, { useState, useEffect } from 'react';
import { Default } from 'react-awesome-spinners';
import {
	PDFViewer,
	Page,
	Text,
	// Link,
	Image,
	View,
	Document,
	StyleSheet,
	Font,
} from '@react-pdf/renderer';
// import { getApplication } from "./../firebase";

// import logo from "./../assets/pdf/logo.png";
import page1 from './../assets/pdf/page1.jpg';
import page2 from './../assets/pdf/page2.jpg';
import page3 from './../assets/pdf/page3.jpg';
import page4 from './../assets/pdf/page4.jpg';

const PDF = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isTimeout, setIsTimeout] = useState(false);
	const [obj, setObj] = useState([]);
	const [yourJobStatusDetail, setYourJobStatusDetail] = useState([]);
	const [yourJobStatusDetailTotal, setYourJobStatusDetailTotal] = useState(0);
	const [YourJobExpense, setYourJobExpense] = useState(0);
	const [YourJobExpenseTotal, setYourJobExpenseTotal] = useState(0);
	const [cashSaving, setCashSaving] = useState([]);
	const [cashSavingTotal, setCashSavingTotal] = useState(0);
	const [insurancePolicy, setInsurancePolicy] = useState([]);
	const [insurancePolicyFaceValue, setInsurancePolicyFaceValue] = useState(0);
	const [insurancePolicyCashValue, setInsurancePolicyCashValue] = useState(0);
	const [insurancePolicyLoans, setInsurancePolicyLoans] = useState(0);
	const [shareBond, setShareBond] = useState(0);
	const [shareBondPresentMarketValue, setShareBondPresentMarketValue] = useState(0);
	const [realEstatePersonal, setRealEstatePersonal] = useState([]);
	const [realEstatePersonalMarketValue, setRealEstatePersonalMarketValue] = useState(0);
	const [realEstatePersonalPresentLoanBal, setRealEstatePersonalPresentLoanBal] = useState(0);
	const [realEstatePersonalMonthlyRentalIncome, setRealEstatePersonalMonthlyRentalIncome] = useState(0);
	const [realEstatePersonalMonthlyPayment, setRealEstatePersonalMonthlyPayment] = useState(0);
	const [realEstateSecond, setRealEstateSecond] = useState({});
	const [realEstateInvestment, setRealEstateInvestment] = useState([]);
	const [realEstateInvestmentMarketValue, setRealEstateInvestmentMarketValue] = useState(0);
	const [realEstateInvestmentPresentLoanBal, setRealEstateInvestmentPresentLoanBal] = useState(0);
	const [realEstateInvestmentMonthlyRentalIncome, setRealEstateInvestmentMonthlyRentalIncome] = useState(0);
	const [realEstateInvestmentMonthlyPayment, setRealEstateInvestmentMonthlyPayment] = useState(0);
	const [realEstateInvestmentNoMarketValue, setRealEstateInvestmentNoMarketValue] = useState(0);
	const [realEstateInvestmentNoPresentLoanBal, setRealEstateInvestmentNoPresentLoanBal] = useState(0);
	const [realEstateInvestmentNoMonthlyRentalIncome, setRealEstateInvestmentNoMonthlyRentalIncome] = useState(0);
	const [realEstateInvestmentNoMonthlyPayment, setRealEstateInvestmentNoMonthlyPayment] = useState(0);
	const [mortgageContract, setMortgageContract] = useState(0);
	const [mortgageContractBalanceDue, setMortgageContractBalanceDue] = useState(0);
	const [profitPension, setProfitPension] = useState(0);
	const [profitPensionAccountBalance, setProfitPensionAccountBalance] = useState(0);
	const [profitPensionAmountTotallyVested, setProfitPensionAmountTotallyVested] = useState(0);
	const [profitPensionLoans, setProfitPensionLoans] = useState(0);
	const [payableLoan, setPayableLoan] = useState(0);
	const [payableLoanMonthlyPayment, setPayableLoanMonthlyPayment] = useState(0);
	const [payableLoanUnpaidBalance, setPayableLoanUnpaidBalance] = useState(0);
	const [contingentLiabilities, setContingentLiabilities] = useState([]);
	const [contingentLiabilitiesQuestionnaire, setContingentLiabilitiesQuestionnaire] = useState([]);
	const [contingentLiabilitiesQuestionnaireOther, setContingentLiabilitiesQuestionnaireOther] = useState([]);

	const [totalAssetsAmount, setTotalAssetsAmount] = useState(0);
	const [subtotalLiabilitiesAmount, setSubtotalLiabilitiesAmount] = useState(0);
	const [netWorthAmount, setNetWorthAmount] = useState(0);
	const [space, setSpace] = useState(60);

	useEffect(() => {
		async function fetchPost() {
			const pdfData = localStorage.getItem('pdfData');
			const pdfDataJSON = JSON.parse(pdfData);
			let x;
			let total;

			setObj(pdfDataJSON);
			console.log({ pdfDataJSON });
			setYourJobStatusDetail(pdfDataJSON.YourJobStatusDetail);

			total = 0;
			total =
				parseFloat(total) +
				(YourJobExpense.FederalIncomeOtherTaxes ? parseFloat(YourJobExpense.FederalIncomeOtherTaxes) : 0) +
				(YourJobExpense.StateIncomeOtherTaxes ? parseFloat(YourJobExpense.StateIncomeOtherTaxes) : 0) +
				(YourJobExpense.RentalPaymentsCoopCondoMaintenance
					? parseFloat(YourJobExpense.RentalPaymentsCoopCondoMaintenance)
					: 0) +
				(YourJobExpense.MortgagePayments ? parseFloat(YourJobExpense.MortgagePayments) : 0) +
				(YourJobExpense.PropertyTaxes ? parseFloat(YourJobExpense.PropertyTaxes) : 0) +
				(YourJobExpense.ResidentialSchedule5 ? parseFloat(YourJobExpense.ResidentialSchedule5) : 0) +
				(YourJobExpense.InvestmentSchedule5 ? parseFloat(YourJobExpense.InvestmentSchedule5) : 0) +
				(YourJobExpense.Residential ? parseFloat(YourJobExpense.Residential) : 0) +
				(YourJobExpense.Investment ? parseFloat(YourJobExpense.Investment) : 0) +
				(YourJobExpense.InterestPrincipalPaymentsLoans
					? parseFloat(YourJobExpense.InterestPrincipalPaymentsLoans)
					: 0) +
				(YourJobExpense.InsuranceIncludingAutoHomeLifeDisabilityOther
					? parseFloat(YourJobExpense.InsuranceIncludingAutoHomeLifeDisabilityOther)
					: 0) +
				(YourJobExpense.InvestmentsIncludingTaxShelters
					? parseFloat(YourJobExpense.InvestmentsIncludingTaxShelters)
					: 0) +
				(YourJobExpense.AlimonyChildSupport ? parseFloat(YourJobExpense.AlimonyChildSupport) : 0) +
				(YourJobExpense.Tuition ? parseFloat(YourJobExpense.Tuition) : 0) +
				(YourJobExpense.OtherLivingExpenses ? parseFloat(YourJobExpense.OtherLivingExpenses) : 0) +
				(YourJobExpense.MedicalExpenses ? parseFloat(YourJobExpense.MedicalExpenses) : 0) +
				(YourJobExpense.OtherExpenseList ? parseFloat(YourJobExpense.OtherExpenseList) : 0);
			total = total.toLocaleString();
			setYourJobExpenseTotal(total);
			// YourJobExpense

			// CashSaving
			setCashSaving(Object.values(pdfDataJSON.CashSaving));

			total = 0;
			for (x = 0; x < cashSaving.length; x++) {
				total =
					parseFloat(total) + (cashSaving[x].AccountBalance ? parseFloat(cashSaving[x].AccountBalance) : 0);
			}
			total = total.toLocaleString();
			setCashSavingTotal(total);
			// CashSaving

			// InsurancePolicy
			setInsurancePolicy(Object.values(pdfDataJSON.InsurancePolicy));

			total = 0;
			for (x = 0; x < insurancePolicy.length; x++) {
				total =
					parseFloat(total) + (insurancePolicy[x].FaceValue ? parseFloat(insurancePolicy[x].FaceValue) : 0);
			}
			total = total.toLocaleString();
			setInsurancePolicyFaceValue(total);

			total = 0;
			for (x = 0; x < insurancePolicy.length; x++) {
				total =
					parseFloat(total) + (insurancePolicy[x].CashValue ? parseFloat(insurancePolicy[x].CashValue) : 0);
			}
			total = total.toLocaleString();
			setInsurancePolicyCashValue(total);

			total = 0;
			for (x = 0; x < insurancePolicy.length; x++) {
				total = parseFloat(total) + (insurancePolicy[x].Loans ? parseFloat(insurancePolicy[x].Loans) : 0);
			}
			total = total.toLocaleString();
			setInsurancePolicyLoans(total);
			// InsurancePolicy

			// ShareBond
			setShareBond(Object.values(pdfDataJSON.ShareBond));

			total = 0;
			for (x = 0; x < shareBond.length; x++) {
				total =
					parseFloat(total) +
					(shareBond[x].PresentMarketValue ? parseFloat(shareBond[x].PresentMarketValue) : 0);
			}
			total = total.toLocaleString();
			setShareBondPresentMarketValue(total);
			// ShareBond

			// MortgageContract
			setMortgageContract(Object.values(pdfDataJSON.MortgageContract));

			total = 0;
			for (x = 0; x < mortgageContract.length; x++) {
				total =
					parseFloat(total) +
					(mortgageContract[x].BalanceDue ? parseFloat(mortgageContract[x].BalanceDue) : 0);
			}
			total = total.toLocaleString();
			setMortgageContractBalanceDue(total);
			// MortgageContract

			// RealEstatePersonal
			setRealEstatePersonal(Object.values(pdfDataJSON.RealEstatePersonal));

			total = 0;
			for (x = 0; x < realEstatePersonal.length; x++) {
				total =
					parseFloat(total) +
					(realEstatePersonal[x].MarketValue ? parseFloat(realEstatePersonal[x].MarketValue) : 0);
			}
			total = parseFloat(total) + (realEstateSecond.MarketValue ? parseFloat(realEstateSecond.MarketValue) : 0);
			total = total.toLocaleString();
			setRealEstatePersonalMarketValue(total);

			total = 0;
			for (x = 0; x < realEstatePersonal.length; x++) {
				total =
					parseFloat(total) +
					(realEstatePersonal[x].PresentLoanBal ? parseFloat(realEstatePersonal[x].PresentLoanBal) : 0);
			}
			total =
				parseFloat(total) + (realEstateSecond.PresentLoanBal ? parseFloat(realEstateSecond.PresentLoanBal) : 0);
			total = total.toLocaleString();
			setRealEstatePersonalPresentLoanBal(total);

			total = 0;
			for (x = 0; x < realEstatePersonal.length; x++) {
				total =
					parseFloat(total) +
					(realEstatePersonal[x].MonthlyRentalIncome
						? parseFloat(realEstatePersonal[x].MonthlyRentalIncome)
						: 0);
			}
			total =
				parseFloat(total) +
				(realEstateSecond.MonthlyRentalIncome ? parseFloat(realEstateSecond.MonthlyRentalIncome) : 0);
			total = total.toLocaleString();
			setRealEstatePersonalMonthlyRentalIncome(total);

			total = 0;
			for (x = 0; x < realEstatePersonal.length; x++) {
				total =
					parseFloat(total) +
					(realEstatePersonal[x].MonthlyPayment ? parseFloat(realEstatePersonal[x].MonthlyPayment) : 0);
			}
			total =
				parseFloat(total) + (realEstateSecond.MonthlyPayment ? parseFloat(realEstateSecond.MonthlyPayment) : 0);
			total = total.toLocaleString();
			setRealEstatePersonalMonthlyPayment(total);
			// RealEstatePersonal

			// RealEstateSecond
			setRealEstateSecond(pdfDataJSON.RealEstateSecond);
			// RealEstateSecond

			// RealEstateInvestment
			setRealEstateInvestment(Object.values(pdfDataJSON.RealEstateInvestment));

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].MarketValue ? parseFloat(realEstateInvestment[x].MarketValue) : 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentMarketValue(total);

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].PresentLoanBal
							? parseFloat(realEstateInvestment[x].PresentLoanBal)
							: 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentPresentLoanBal(total);

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].MonthlyRentalIncome
							? parseFloat(realEstateInvestment[x].MonthlyRentalIncome)
							: 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentMonthlyRentalIncome(total);

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership === '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].MonthlyPayment
							? parseFloat(realEstateInvestment[x].MonthlyPayment)
							: 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentMonthlyPayment(total);

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].MarketValue ? parseFloat(realEstateInvestment[x].MarketValue) : 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentNoMarketValue(total);

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].PresentLoanBal
							? parseFloat(realEstateInvestment[x].PresentLoanBal)
							: 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentNoPresentLoanBal(total);

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].MonthlyRentalIncome
							? parseFloat(realEstateInvestment[x].MonthlyRentalIncome)
							: 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentNoMonthlyRentalIncome(total);

			total = 0;
			for (x = 0; x < realEstateInvestment.length; x++) {
				if (realEstateInvestment[x].InvestmentOwnedSomeoneOwnership !== '100') {
					total =
						parseFloat(total) +
						(realEstateInvestment[x].MonthlyPayment
							? parseFloat(realEstateInvestment[x].MonthlyPayment)
							: 0);
				}
			}
			total = total.toLocaleString();
			setRealEstateInvestmentNoMonthlyPayment(total);
			// RealEstateInvestment

			// ProfitPension
			setProfitPension(Object.values(pdfDataJSON.ProfitPension));

			total = 0;
			for (x = 0; x < profitPension.length; x++) {
				total =
					parseFloat(total) +
					(profitPension[x].AccountBalance ? parseFloat(profitPension[x].AccountBalance) : 0);
			}
			total = total.toLocaleString();
			setProfitPensionAccountBalance(total);

			total = 0;
			for (x = 0; x < profitPension.length; x++) {
				total =
					parseFloat(total) +
					(profitPension[x].AmountTotallyVested ? parseFloat(profitPension[x].AmountTotallyVested) : 0);
			}
			total = total.toLocaleString();
			setProfitPensionAmountTotallyVested(total);

			total = 0;
			for (x = 0; x < profitPension.length; x++) {
				total = parseFloat(total) + (profitPension[x].Loans ? parseFloat(profitPension[x].Loans) : 0);
			}
			total = total.toLocaleString();
			setProfitPensionLoans(total);
			// ProfitPension

			// PayableLoan
			setPayableLoan(Object.values(pdfDataJSON.PayableLoan));

			total = 0;
			for (x = 0; x < payableLoan.length; x++) {
				total =
					parseFloat(total) + (payableLoan[x].MonthlyPayment ? parseFloat(payableLoan[x].MonthlyPayment) : 0);
			}
			total = total.toLocaleString();
			setPayableLoanMonthlyPayment(total);

			total = 0;
			for (x = 0; x < payableLoan.length; x++) {
				total =
					parseFloat(total) + (payableLoan[x].UnpaidBalance ? parseFloat(payableLoan[x].UnpaidBalance) : 0);
			}
			total = total.toLocaleString();
			setPayableLoanUnpaidBalance(total);
			// PayableLoan

			// ContingentLiabilities
			setContingentLiabilities(Object.values(pdfDataJSON.ContingentLiabilities));
			// ContingentLiabilities

			// ContingentLiabilitiesQuestionnaire
			setContingentLiabilitiesQuestionnaire(pdfDataJSON.ContingentLiabilitiesQuestionnaire);
			// ContingentLiabilitiesQuestionnaire

			// ContingentLiabilitiesQuestionnaireOther
			setContingentLiabilitiesQuestionnaireOther(pdfDataJSON.ContingentLiabilitiesQuestionnaireOther);
			// ContingentLiabilitiesQuestionnaireOther

			// totalAssetsAmount // subtotalLiabilitiesAmount // netWorthAmount
			total = 0;
			total =
				parseFloat(total) +
				(obj.CompleteForm.valueCompaniesAmount ? parseFloat(obj.CompleteForm.valueCompaniesAmount) : 0) +
				(obj.CompleteForm.automobilesAmount ? parseFloat(obj.CompleteForm.automobilesAmount) : 0) +
				(obj.CompleteForm.personalPropertyAmount ? parseFloat(obj.CompleteForm.personalPropertyAmount) : 0) +
				(obj.CompleteForm.otherTangibleAssetsAmount
					? parseFloat(obj.CompleteForm.otherTangibleAssetsAmount)
					: 0) +
				(obj.CompleteForm.shortTermNotesAmount ? parseFloat(obj.CompleteForm.shortTermNotesAmount) : 0) +
				(obj.CompleteForm.installmentLoansContractsAmount
					? parseFloat(obj.CompleteForm.installmentLoansContractsAmount)
					: 0) +
				(obj.CompleteForm.taxesFederalStateAmount ? parseFloat(obj.CompleteForm.taxesFederalStateAmount) : 0) +
				(obj.CompleteForm.loansAgainstRetirementAmount
					? parseFloat(obj.CompleteForm.loansAgainstRetirementAmount)
					: 0) +
				(obj.CompleteForm.otherLiabilitiesAmount ? parseFloat(obj.CompleteForm.otherLiabilitiesAmount) : 0);
			// total = total.toLocaleString();
			setTotalAssetsAmount(total);

			total = 0;
			total =
				parseFloat(total) +
				(obj.CompleteForm.valueCompaniesAmount ? parseFloat(obj.CompleteForm.valueCompaniesAmount) : 0) +
				(obj.CompleteForm.automobilesAmount ? parseFloat(obj.CompleteForm.automobilesAmount) : 0) +
				(obj.CompleteForm.personalPropertyAmount ? parseFloat(obj.CompleteForm.personalPropertyAmount) : 0) +
				(obj.CompleteForm.otherTangibleAssetsAmount
					? parseFloat(obj.CompleteForm.otherTangibleAssetsAmount)
					: 0) +
				(obj.CompleteForm.shortTermNotesAmount ? parseFloat(obj.CompleteForm.shortTermNotesAmount) : 0);
			// total = total.toLocaleString();
			setSubtotalLiabilitiesAmount(total);

			total = 0;
			total =
				parseFloat(total) +
				(totalAssetsAmount ? parseFloat(totalAssetsAmount) : 0) -
				(subtotalLiabilitiesAmount ? parseFloat(subtotalLiabilitiesAmount) : 0);
			total = total.toLocaleString();

			setNetWorthAmount(total);
			// totalAssetsAmount // subtotalLiabilitiesAmount // netWorthAmount

			// Adjusting space for "Applicant returns currently being contested" tick mark
			if (
				pdfDataJSON?.ContingentLiabilitiesQuestionnaireOther?.applicantReturnsCurrentlyBeingContested ===
					'Yes' ||
				pdfDataJSON?.ContingentLiabilitiesQuestionnaireOther?.coApplicantReturnsCurrentlyBeingContested ===
					'Yes'
			)
				setSpace(13);

			setTimeout(() => {
				setIsLoading(false);
				setIsTimeout(true);
			}, 500);
		}

		fetchPost();
	}, [obj.length]);

	if (isLoading || !isTimeout) {
		return (
			<div>
				<div style={styles.Ring}>
					<Default />
				</div>
			</div>
		);
	} else {
		return (
			<PDFViewer style={styles.viewer}>
				<Document>
					<Page style={styles.body}>
						{/*
            <View style={styles.header} fixed><Image style={styles.logo} src={logo} /></View>
            */}

						<Image style={styles.page1_img} src={page1} />

						<View style={[styles.row, styles.applicant_co_applicant]}>
							<View style={[styles.col, styles.width50]}>
								<Text style={[styles.text]}>Full Name: {obj ? obj.AboutYourSelf.FullName : ' '}</Text>
								<Text style={[styles.text]}>
									Street Address: {obj ? obj.YourLocation.StreetAddress : ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									City: {obj ? obj.YourLocation.City : ' '} / State:{' '}
									{obj ? obj.YourLocation.State : ' '} / Zip: {obj ? obj.YourLocation.Zip : ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Country: {obj ? obj.YourLocation.Country : ' '}
								</Text>

								<View style={[styles.row, { marginTop: '3px' }]}>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											Since: {obj ? obj.YourLocation.SinceYear : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											{obj ? obj.YourLocation.PropertyType : ' '}{' '}
											{obj
												? obj.YourLocation.PropertyType === 'rent'
													? 'Rent $' + obj.YourLocation.MonthlyPayment + '/Month'
													: ' '
												: ' '}
										</Text>
									</View>
								</View>

								<View style={[styles.row, { marginTop: '1px' }]}>
									<View style={[styles.col, styles.width65]}>
										<Text style={[styles.text]}>
											Social Security # {obj ? obj.AboutYourSelf.SocialSecurity : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width35]}>
										<Text style={[styles.text]}>
											Date Of Birth {obj ? obj.AboutYourSelf.DateOfBirth : ' '}
										</Text>
									</View>
								</View>

								<View style={[styles.row, { marginTop: '2px' }]}>
									<View style={[styles.col, styles.width65]}>
										<Text style={[styles.text]}>
											Phone: Residence {obj ? obj.YourLocation.PhoneNumber : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width35]}>
										<Text style={[styles.text]}>
											Work: {obj ? obj.YourJobStatus.WorkPhone : ' '}
										</Text>
									</View>
								</View>

								<Text style={[styles.text, { marginTop: '2px' }]}>
									Employer: {obj ? obj.YourJobStatus.WhoYourEmployer : ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Address: {obj ? obj.YourJobStatus.StreetAddress : ' '}
								</Text>
								<View style={[styles.row, { marginTop: '2px' }]}>
									<View style={[styles.col, styles.width65]}>
										<Text style={[styles.text]}>
											Position/Title: {obj ? obj.YourJobStatus.WhatPositionTitle : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width35]}>
										<Text style={[styles.text]}>
											Since: {obj ? obj.YourJobStatus.WhenDidYouStart : ' '}
										</Text>
									</View>
								</View>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Dependents: {obj ? obj.AboutYourSelf.Dependents : ' '} |{' '}
									{obj
										? Array.isArray(obj.AboutYourSelf.dependentsAgeAYS)
											? obj.AboutYourSelf.dependentsAgeAYS.join(', ')
											: obj.AboutYourSelf.dependentsAgeAYS
										: ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Marital Status: {obj ? obj.AboutYourSelf.Status : ' '}
								</Text>
							</View>
							<View style={[styles.col, styles.width50]}>
								<Text style={[styles.text]}>
									Full Name: {obj ? obj.AboutYourCoAplicant.FullName : ' '}
								</Text>
								<Text style={[styles.text]}>
									Street Address: {obj ? obj.CoAplicantLocation.streetAddress : ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									City: {obj ? obj.CoAplicantLocation.city : ' '} / State:{' '}
									{obj ? obj.CoAplicantLocation.state : ' '} / Zip:{' '}
									{obj ? obj.CoAplicantLocation.zip : ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Country: {obj ? obj.CoAplicantLocation.country : ' '}
								</Text>

								<View style={[styles.row, { marginTop: '3px' }]}>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											Since: {obj ? obj.CoAplicantLocation.since : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											{obj ? obj.CoAplicantLocation.propertyType : ' '}{' '}
											{obj
												? obj.CoAplicantLocation.propertyType === 'rent'
													? '$' + obj.CoAplicantLocation.rent + '/Month'
													: ' '
												: ' '}
										</Text>
									</View>
								</View>

								<View style={[styles.row, { marginTop: '1px' }]}>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											Social Security # {obj ? obj.AboutYourCoAplicant.SocialSecurity : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											Date Of Birth {obj ? obj.AboutYourCoAplicant.DateOfBirth : ' '}
										</Text>
									</View>
								</View>

								<View style={[styles.row, { marginTop: '2px' }]}>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											Phone: Residence {obj ? obj.CoAplicantLocation.phone : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width50]}>
										<Text style={[styles.text]}>
											Work: {obj ? obj.CoAplicantLocation.phone : ' '}
										</Text>
									</View>
								</View>

								<Text style={[styles.text, { marginTop: '2px' }]}>
									Employer: {obj ? obj.CoApplicantJobStatus.employer : ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Address: {obj ? obj.CoApplicantJobStatus.streetAddress : ' '}
								</Text>
								<View style={[styles.row, { marginTop: '2px' }]}>
									<View style={[styles.col, styles.width65]}>
										<Text style={[styles.text]}>
											Position/Title: {obj ? obj.CoApplicantJobStatus.position : ' '}
										</Text>
									</View>
									<View style={[styles.col, styles.width35]}>
										<Text style={[styles.text]}>
											Since: {obj ? obj.CoApplicantJobStatus.start : ' '}
										</Text>
									</View>
								</View>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Dependents: {obj ? obj.AboutYourCoAplicant.dependents : ' '} |{' '}
									{obj
										? Array.isArray(obj.AboutYourCoAplicant.DependentAges)
											? obj.AboutYourCoAplicant.DependentAges.join(', ')
											: obj.AboutYourCoAplicant.DependentAges
										: ' '}
								</Text>
								<Text style={[styles.text, { marginTop: '3px' }]}>
									Marital Status: {obj ? obj.AboutYourCoAplicant.Status : ' '}
								</Text>
							</View>
						</View>

						<View style={[styles.row, styles.CompleteForm, styles.width50]}>
							<View style={[styles.col, styles.width100]}>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* cash schedule 1 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* cash schedule 1 */}
										{cashSavingTotal || '0'}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* {(obj) ? obj.CompleteForm.automobiles : " "} */}
										{/* scurities schedule 2 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* {(obj) ? obj.CompleteForm.automobilesAmount : " "} */}
										{/* scurities schedule 2 */}

										{shareBondPresentMarketValue || '0'}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{obj ? obj.CompleteForm.valueCompanies : ' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{obj ? obj.CompleteForm.valueCompaniesAmount : ' '}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}></Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Life insurance cash value Schedule 3 */}
										{/* {(obj) ? obj.CompleteForm.otherTangibleAssets : " "} */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Life insurance cash value Schedule 3 */}

										{insurancePolicyCashValue || '0'}
										{/* {(obj) ? obj.CompleteForm.otherTangibleAssetsAmount : " "} */}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/*Homestead Schedule 5 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/*Homestead Schedule 5 */}
										{realEstatePersonalMarketValue || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Other Real estate schedule 5 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Other Real estate schedule 5 */}
										{realEstateInvestmentNoMarketValue || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Mortgage and contract I own schedule 4 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Mortgage and contract I own schedule 4 */}
										{mortgageContractBalanceDue || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Profit sharing pension IRA schedule 6 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{profitPensionAccountBalance || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{obj ? obj.CompleteForm.automobiles : ' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{obj ? obj.CompleteForm.automobilesAmount : ' '}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}></Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}></Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{obj ? obj.CompleteForm.personalProperty : ' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{obj ? obj.CompleteForm.personalAmount : ' '}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}></Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{obj ? obj.CompleteForm.otherTangibleAssets : ' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{' '}
										{obj ? obj.CompleteForm.otherTangibleAssetsAmount : ' '}
									</Text>
								</View>
							</View>
						</View>

						<View style={[styles.row, styles.CompleteForm2, styles.width50]}>
							<View style={[styles.col, styles.width100]}>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Loans to bank schedule 7 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Loans to bank schedule 7 */}
										{payableLoanMonthlyPayment || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{' '}
										{obj ? obj.CompleteForm.shortTermNotes : ' '}{' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{' '}
										{obj ? obj.CompleteForm.shortTermNotesAmount : ' '}{' '}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Insurance Loans schedule 3 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Insurance Loans schedule 3 */}
										{insurancePolicyLoans || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{obj ? obj.CompleteForm.installmentLoansContracts : ' '}{' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{obj ? obj.CompleteForm.installmentLoansContractsAmount : ' '}{' '}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Mortgage on my home schedule 5*/}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Mortgage on my home schedule 5*/}
										{realEstatePersonalMarketValue || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Mortgage on other real estate schedule 5 */}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Mortgage on other real estate schedule 5 */}
										{realEstateInvestmentMarketValue || 0}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{obj ? obj.CompleteForm.taxesFederalState : ' '}{' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{obj ? obj.CompleteForm.taxesFederalStateAmount : ' '}{' '}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}></Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{/* Loans against retirement */}
										{obj?.CompleteForm?.loansAgainstRetirement}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{/* Loans against retirement */}
										{obj?.CompleteForm?.loansAgainstRetirementAmount}
									</Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}></Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}></Text>
								</View>
								<View style={[styles.row]}>
									<Text style={[styles.text, { height: '9px', width: '174px' }]}> </Text>
									<Text style={[styles.text, { height: '9px', width: '36px' }]}>
										{' '}
										{obj ? obj.CompleteForm.otherLiabilities : ' '}
									</Text>
									<Text style={[styles.text, { height: '9px', width: '40px' }]}>
										{' '}
										{obj ? obj.CompleteForm.otherLiabilitiesAmount : ' '}
									</Text>
								</View>
							</View>
						</View>

						<Text
							style={[
								styles.text,
								{
									position: 'absolute',
									top: '600px',
									left: '252px',
									height: '9px',
									width: '40px',
								},
							]}>
							{/* Total Assets */}
							{totalAssetsAmount ? totalAssetsAmount.toLocaleString() : '0'}
						</Text>
						<Text
							style={[
								styles.text,
								{
									position: 'absolute',
									top: '588px',
									left: '514px',
									height: '9px',
									width: '40px',
								},
							]}>
							{/* Total Liabilities */}
							{subtotalLiabilitiesAmount ? subtotalLiabilitiesAmount.toLocaleString() : '0'}
						</Text>
						<Text
							style={[
								styles.text,
								{
									position: 'absolute',
									top: '600px',
									left: '514px',
									height: '9px',
									width: '40px',
								},
							]}>
							{/* Net Worth */}
							{netWorthAmount ? netWorthAmount.toLocaleString() : '0'}
						</Text>
					</Page>

					<Page style={styles.body}>
						<Image style={styles.page2_img} src={page2} />

						{obj ? (
							<View style={[styles.row, styles.YourJobStatusDetail]}>
								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.YourIncome : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.BonusesCommissions : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{/* Rental Income Schedule 5 */}
										{realEstatePersonalMonthlyRentalIncome +
											realEstateInvestmentMonthlyRentalIncome || 0}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.BonusesCommissions : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.DividendIncome : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.CapitalGains : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.PartnershipIncome : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.OtherInvestmentIncome : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetail ? yourJobStatusDetail.OtherIncome : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{yourJobStatusDetailTotal ? yourJobStatusDetailTotal : '0'}
									</Text>
								</View>
							</View>
						) : (
							' '
						)}

						{obj ? (
							<View style={[styles.row, styles.YourJobExpense]}>
								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.FederalIncomeOtherTaxes : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.StateIncomeOtherTaxes : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.RentalPaymentsCoopCondoMaintenance : '0'}
									</Text>
								</View>

								{/*
                <View style={[styles.col, {width: "100%", height: 10.7}]}>
                  <Text style={[styles.text, {fontSize: 6}]}>{(YourJobExpense) ? YourJobExpense.MortgagePayments : "0"}</Text>
                </View>

                <View style={[styles.col, {width: "100%", height: 10.7}]}>
                  <Text style={[styles.text, {fontSize: 6}]}>{(YourJobExpense) ? YourJobExpense.PropertyTaxes : "0"}</Text>
                </View>
                */}

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.ResidentialSchedule5 : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.InvestmentSchedule5 : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.Residential : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.Investment : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.InterestPrincipalPaymentsLoans : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense
											? YourJobExpense.InsuranceIncludingAutoHomeLifeDisabilityOther
											: '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.InvestmentsIncludingTaxShelters : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.AlimonyChildSupport : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.Tuition : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.OtherLivingExpenses : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.MedicalExpenses : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpense ? YourJobExpense.OtherExpenseList : '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}></View>

								<View style={[styles.col, { width: '100%', height: 10.7 }]}>
									<Text style={[styles.text, { fontSize: 6 }]}>
										{YourJobExpenseTotal ? YourJobExpenseTotal : '0'}
									</Text>
								</View>
							</View>
						) : (
							' '
						)}

						<View style={[styles.row, styles.CashSaving]}>
							{obj
								? cashSaving.map((item, i) => {
										return (
											<View style={[styles.row]} key={i}>
												<View style={[styles.col, { width: '218px' }]}>
													<Text style={[styles.text]}>
														{item.NameOfBank ? item.NameOfBank : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '85px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.TypeAccount ? item.TypeAccount : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '76px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.WhoseName ? item.WhoseName : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '63px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.group ? item.group : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '83px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.AccountBalance
															? parseFloat(item.AccountBalance).toLocaleString()
															: '0'}
													</Text>
												</View>
											</View>
										);
								  })
								: ' '}
						</View>

						<View style={[styles.row, styles.CashSaving, { width: '83px', top: '393px', left: '477px' }]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{cashSavingTotal ? cashSavingTotal : '0'}
							</Text>
						</View>

						<View style={[styles.row, styles.InsurancePolicy]}>
							{obj
								? insurancePolicy.map((item, i) => {
										return (
											<View style={[styles.row]} key={i}>
												<View style={[styles.col, { width: '115px' }]}>
													<Text style={[styles.text]}>
														{item.InsuranceCompany ? item.InsuranceCompany : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '62px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.Insured ? item.Insured : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '126px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.Beneficiary ? item.Beneficiary : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '76px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.FaceValue
															? parseFloat(item.FaceValue).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '63px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.CashValue
															? parseFloat(item.CashValue).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '83px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.Loans ? parseFloat(item.Loans).toLocaleString() : '0'}
													</Text>
												</View>
											</View>
										);
								  })
								: ' '}
						</View>

						<View
							style={[
								styles.row,
								styles.InsurancePolicy,
								{ width: '83px', top: '641px', left: '338px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{insurancePolicyFaceValue ? insurancePolicyFaceValue : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.InsurancePolicy,
								{ width: '83px', top: '641px', left: '414px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{insurancePolicyCashValue ? insurancePolicyCashValue : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.InsurancePolicy,
								{ width: '83px', top: '641px', left: '477px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{insurancePolicyLoans ? insurancePolicyLoans : '0'}
							</Text>
						</View>

						<View style={[styles.row, styles.ShareBond]}>
							{obj
								? shareBond.map((item, i) => {
										return (
											<View style={[styles.row]} key={'ShareBond_' + i}>
												<View style={[styles.col, { width: '88px' }]}>
													<Text style={[styles.text]}>
														{item.SharesBondAmount ? item.SharesBondAmount : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '89px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.Description ? item.Description : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '85px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.WhoseNameRegistered ? item.WhoseNameRegistered : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '69px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.group ? item.group : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '48px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.Cost ? parseFloat(item.Cost).toLocaleString() : '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '63px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.PresentMarketValue
															? parseFloat(item.PresentMarketValue).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '37px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.ExchangeListedUnlisted
															? item.ExchangeListedUnlisted
															: ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '46px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.ControlledRestricted ? item.ControlledRestricted : ' '}
													</Text>
												</View>
											</View>
										);
								  })
								: ' '}
						</View>

						<View style={[styles.row, styles.ShareBond, { width: '63px', top: '533px', left: '414px' }]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{shareBondPresentMarketValue ? shareBondPresentMarketValue : '0'}
							</Text>
						</View>
					</Page>

					<Page style={styles.body}>
						<Image style={styles.page3_img} src={page3} />

						<View style={[styles.row, styles.MortgageContract]}>
							{obj
								? mortgageContract.map((item, i) => {
										return (
											<View style={[styles.row]} key={'MortgageContract_' + i}>
												<View style={[styles.col, { width: '88px' }]}>
													<Text style={[styles.text]}>
														{item.NameDebtor ? item.NameDebtor : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '129px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.DescriptionProperty ? item.DescriptionProperty : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '86px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.FirstSecondLien ? item.FirstSecondLien : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '28px' }]}>
													<Text
														style={[
															styles.text,
															{
																margin: 'auto',
																fontSize: 5,
															},
														]}>
														{item.DateMaturity ? item.DateMaturity : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '148px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.RepaymentTerms ? item.RepaymentTerms : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '46px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.BalanceDue
															? parseFloat(item.BalanceDue).toLocaleString()
															: '0'}
													</Text>
												</View>
											</View>
										);
								  })
								: ' '}
						</View>

						<View
							style={[
								styles.row,
								styles.MortgageContract,
								{ width: '46px', top: '105px', left: '514px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{mortgageContractBalanceDue ? mortgageContractBalanceDue : '0'}
							</Text>
						</View>

						<View style={[styles.row, styles.RealEstatePersonal]}>
							{obj
								? realEstatePersonal.map((item, i) => {
										return (
											<View style={[styles.row]} key={'RealEstatePersonal_' + i}>
												<View style={[styles.col, { width: '116px' }]}>
													<Text style={[styles.text]}>
														{item.PropertyAddress ? item.PropertyAddress : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '50px' }]}>
													<Text style={[styles.text]}>
														{item.LegalOwner ? item.LegalOwner : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '11px' }]}>
													<Text style={[styles.text]}>
														{item.PurchaseYear ? item.PurchaseYear : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '40px' }]}>
													<Text style={[styles.text]}>
														{item.PurchasePrice ? item.PurchasePrice : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '45px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.MarketValue
															? parseFloat(item.MarketValue).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '42px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.PresentLoanBal
															? parseFloat(item.PresentLoanBal).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '27px' }]}>
													<Text style={[styles.text]}>
														{item.InterestRate ? item.InterestRate : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '48px' }]}>
													<Text style={[styles.text]}>
														{item.LoanMaturityDate ? item.LoanMaturityDate : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '63px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.MonthlyRentalIncome
															? parseFloat(item.MonthlyRentalIncome).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '38px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.MonthlyPayment
															? parseFloat(item.MonthlyPayment).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '45px' }]}>
													<Text style={[styles.text]}>{item.Lender ? item.Lender : ' '}</Text>
												</View>
											</View>
										);
								  })
								: ' '}

							{obj ? (
								realEstateSecond ? (
									<View style={[styles.row]} key={'RealEstateSecond'}>
										<View style={[styles.col, { width: '116px' }]}>
											<Text style={[styles.text]}>
												{realEstateSecond.PropertyAddress
													? realEstateSecond.PropertyAddress
													: ' '}
											</Text>
										</View>

										<View style={[styles.col, { width: '50px' }]}>
											<Text style={[styles.text]}>
												{realEstateSecond.LegalOwner ? realEstateSecond.LegalOwner : ' '}
											</Text>
										</View>

										<View style={[styles.col, { width: '11px' }]}>
											<Text style={[styles.text]}>
												{realEstateSecond.PurchaseYear ? realEstateSecond.PurchaseYear : ' '}
											</Text>
										</View>

										<View style={[styles.col, { width: '40px' }]}>
											<Text style={[styles.text]}>
												{realEstateSecond.PurchasePrice ? realEstateSecond.PurchasePrice : ' '}
											</Text>
										</View>

										<View style={[styles.col, { width: '45px' }]}>
											<Text style={[styles.text, { margin: 'auto' }]}>
												{realEstateSecond.MarketValue
													? parseFloat(realEstateSecond.MarketValue).toLocaleString()
													: '0'}
											</Text>
										</View>

										<View style={[styles.col, { width: '42px' }]}>
											<Text style={[styles.text, { margin: 'auto' }]}>
												{realEstateSecond.PresentLoanBal
													? parseFloat(realEstateSecond.PresentLoanBal).toLocaleString()
													: '0'}
											</Text>
										</View>

										<View style={[styles.col, { width: '27px' }]}>
											<Text style={[styles.text]}>
												{realEstateSecond.InterestRate ? realEstateSecond.InterestRate : ' '}
											</Text>
										</View>

										<View style={[styles.col, { width: '48px' }]}>
											<Text style={[styles.text]}>
												{realEstateSecond.LoanMaturityDate
													? realEstateSecond.LoanMaturityDate
													: ' '}
											</Text>
										</View>

										<View style={[styles.col, { width: '63px' }]}>
											<Text style={[styles.text, { margin: 'auto' }]}>
												{realEstateSecond.MonthlyRentalIncome
													? parseFloat(realEstateSecond.MonthlyRentalIncome).toLocaleString()
													: '0'}
											</Text>
										</View>

										<View style={[styles.col, { width: '38px' }]}>
											<Text style={[styles.text, { margin: 'auto' }]}>
												{realEstateSecond.MonthlyPayment
													? parseFloat(realEstateSecond.MonthlyPayment).toLocaleString()
													: '0'}
											</Text>
										</View>

										<View style={[styles.col, { width: '45px' }]}>
											<Text style={[styles.text]}>
												{realEstateSecond.Lender ? realEstateSecond.Lender : ' '}
											</Text>
										</View>
									</View>
								) : (
									' '
								)
							) : (
								' '
							)}
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '45px', top: '181px', left: '252px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstatePersonalMarketValue ? realEstatePersonalMarketValue : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '42px', top: '181px', left: '297px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstatePersonalPresentLoanBal ? realEstatePersonalPresentLoanBal : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '63px', top: '181px', left: '414px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstatePersonalMonthlyRentalIncome ? realEstatePersonalMonthlyRentalIncome : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '38px', top: '181px', left: '477px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstatePersonalMonthlyPayment ? realEstatePersonalMonthlyPayment : '0'}
							</Text>
						</View>

						<View style={[styles.row, styles.RealEstateInvestment]}>
							{obj
								? realEstateInvestment.map((item, i) => {
										if (item.InvestmentOwnedSomeoneOwnership === '100') {
											return (
												<View style={[styles.row]} key={'RealEstateInvestment_' + i}>
													<View style={[styles.col, { width: '116px' }]}>
														<Text style={[styles.text]}>
															{item.PropertyAddress ? item.PropertyAddress : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '50px' }]}>
														<Text style={[styles.text]}>
															{item.LegalOwner ? item.LegalOwner : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '11px' }]}>
														<Text style={[styles.text]}>
															{item.PurchaseYear ? item.PurchaseYear : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '40px' }]}>
														<Text style={[styles.text]}>
															{item.PurchasePrice ? item.PurchasePrice : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '45px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.MarketValue
																? parseFloat(item.MarketValue).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '42px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.PresentLoanBal
																? parseFloat(item.PresentLoanBal).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '27px' }]}>
														<Text style={[styles.text]}>
															{item.InterestRate ? item.InterestRate : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '48px' }]}>
														<Text style={[styles.text]}>
															{item.LoanMaturityDate ? item.LoanMaturityDate : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '63px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.MonthlyRentalIncome
																? parseFloat(item.MonthlyRentalIncome).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '38px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.MonthlyPayment
																? parseFloat(item.MonthlyPayment).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '45px' }]}>
														<Text style={[styles.text]}>
															{item.Lender ? item.Lender : ' '}
														</Text>
													</View>
												</View>
											);
										}
								  })
								: ' '}
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '45px', top: '238px', left: '252px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentMarketValue ? realEstateInvestmentMarketValue : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '42px', top: '238px', left: '297px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentPresentLoanBal ? realEstateInvestmentPresentLoanBal : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '63px', top: '238px', left: '414px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentMonthlyRentalIncome
									? realEstateInvestmentMonthlyRentalIncome
									: '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '38px', top: '238px', left: '477px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentMonthlyPayment ? realEstateInvestmentMonthlyPayment : '0'}
							</Text>
						</View>

						<View style={[styles.row, styles.RealEstateInvestmentNo]}>
							{obj
								? realEstateInvestment.map((item, i) => {
										if (item.InvestmentOwnedSomeoneOwnership !== '100') {
											return (
												<View style={[styles.row]} key={'RealEstateInvestmentNo_' + i}>
													<View style={[styles.col, { width: '89px' }]}>
														<Text style={[styles.text]}>
															{item.PropertyAddress ? item.PropertyAddress : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '27px' }]}>
														<Text style={[styles.text]}>
															{item.InvestmentOwnedSomeoneOwnership
																? item.InvestmentOwnedSomeoneOwnership
																: ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '50px' }]}>
														<Text style={[styles.text]}>
															{item.LegalOwner ? item.LegalOwner : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '11px' }]}>
														<Text style={[styles.text]}>
															{item.PurchaseYear ? item.PurchaseYear : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '40px' }]}>
														<Text style={[styles.text]}>
															{item.PurchasePrice ? item.PurchasePrice : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '45px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.MarketValue
																? parseFloat(item.MarketValue).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '42px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.PresentLoanBal
																? parseFloat(item.PresentLoanBal).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '27px' }]}>
														<Text style={[styles.text]}>
															{item.InterestRate ? item.InterestRate : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '48px' }]}>
														<Text style={[styles.text]}>
															{item.LoanMaturityDate ? item.LoanMaturityDate : ' '}
														</Text>
													</View>

													<View style={[styles.col, { width: '63px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.MonthlyRentalIncome
																? parseFloat(item.MonthlyRentalIncome).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '38px' }]}>
														<Text
															style={[
																styles.text,
																{
																	margin: 'auto',
																},
															]}>
															{item.MonthlyPayment
																? parseFloat(item.MonthlyPayment).toLocaleString()
																: '0'}
														</Text>
													</View>

													<View style={[styles.col, { width: '45px' }]}>
														<Text style={[styles.text]}>
															{item.Lender ? item.Lender : ' '}
														</Text>
													</View>
												</View>
											);
										}
								  })
								: ' '}
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '45px', top: '323px', left: '252px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentNoMarketValue ? realEstateInvestmentNoMarketValue : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '42px', top: '323px', left: '297px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentNoPresentLoanBal ? realEstateInvestmentNoPresentLoanBal : '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '63px', top: '323px', left: '414px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentNoMonthlyRentalIncome
									? realEstateInvestmentNoMonthlyRentalIncome
									: '0'}
							</Text>
						</View>

						<View
							style={[
								styles.row,
								styles.RealEstatePersonal,
								{ width: '38px', top: '323px', left: '477px' },
							]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{realEstateInvestmentNoMonthlyPayment ? realEstateInvestmentNoMonthlyPayment : '0'}
							</Text>
						</View>

						<View style={[styles.row, styles.ProfitPension]}>
							{obj
								? profitPension.map((item, i) => {
										return (
											<View style={[styles.row]} key={'ProfitPension_' + i}>
												<View style={[styles.col, { width: '166px' }]}>
													<Text style={[styles.text]}>
														{item.NameInstitutionPlan ? item.NameInstitutionPlan : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '51px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.acct ? item.acct : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '114px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.AccountBalance
															? parseFloat(item.AccountBalance).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '148px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.AmountTotallyVested
															? parseFloat(item.AmountTotallyVested).toLocaleString()
															: '0'}
													</Text>
												</View>

												<View style={[styles.col, { width: '46px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.Loans ? parseFloat(item.Loans).toLocaleString() : '0'}
													</Text>
												</View>
											</View>
										);
								  })
								: ' '}
						</View>

						<View
							style={[styles.row, styles.ProfitPension, { width: '114px', top: '420px', left: '252px' }]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{profitPensionAccountBalance ? profitPensionAccountBalance : '0'}
							</Text>
						</View>

						<View
							style={[styles.row, styles.ProfitPension, { width: '148px', top: '420px', left: '366px' }]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{profitPensionAmountTotallyVested ? profitPensionAmountTotallyVested : '0'}
							</Text>
						</View>

						<View
							style={[styles.row, styles.ProfitPension, { width: '46px', top: '420px', left: '514px' }]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{profitPensionLoans ? profitPensionLoans : '0'}
							</Text>
						</View>

						<View style={[styles.row, styles.PayableLoan]}>
							{obj
								? payableLoan.map((item, i) => {
										return (
											<View style={[styles.row]} key={'PayableLoan_' + i}>
												<View style={[styles.col, { width: '89px' }]}>
													<Text style={[styles.text]}>
														{item.ToWhomPayable ? item.ToWhomPayable : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '88px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.Address ? item.Address : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '40px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.InterestRate ? item.InterestRate : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '87px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.CollateralIndicateUnsecured
															? item.CollateralIndicateUnsecured
															: ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '138px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.MonthlyPayment
															? parseFloat(item.MonthlyPayment).toLocaleString()
															: '0'}{' '}
														/ Month
													</Text>
												</View>

												<View style={[styles.col, { width: '38px' }]}>
													<Text
														style={[
															styles.text,
															{
																margin: 'auto',
																fontSize: 5,
															},
														]}>
														{item.MaturityDate ? item.MaturityDate : ' '}
													</Text>
												</View>

												<View style={[styles.col, { width: '45px' }]}>
													<Text style={[styles.text, { margin: 'auto' }]}>
														{item.UnpaidBalance
															? parseFloat(item.UnpaidBalance).toLocaleString()
															: '0'}
													</Text>
												</View>
											</View>
										);
								  })
								: ' '}
						</View>

						<View style={[styles.row, styles.PayableLoan, { width: '138px', top: '580px', left: '339px' }]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{payableLoanMonthlyPayment ? payableLoanMonthlyPayment : '0'} / Month
							</Text>
						</View>

						<View style={[styles.row, styles.PayableLoan, { width: '45px', top: '580px', left: '515px' }]}>
							<Text style={[styles.text, { margin: 'auto' }]}>
								{payableLoanUnpaidBalance ? payableLoanUnpaidBalance : '0'}
							</Text>
						</View>
					</Page>

					<Page style={styles.body}>
						<Image style={styles.page4_img} src={page4} />

						<View style={[styles.row, styles.ContingentLiabilities]}>
							{obj
								? contingentLiabilities.map((item, i) => {
										return (
											<View style={[styles.row]} key={'ContingentLiabilities_' + i}>
												{item.group === 'Yes' ? (
													<>
														<View
															style={[
																styles.col,
																{
																	width: '88px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{item.group ? item.group : ' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '129px',
																},
															]}>
															<Text style={[styles.text]}>
																{item.ToWhomPayable ? item.ToWhomPayable : ' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '114px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{item.Terms ? item.Terms : ' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '111px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{item.MaximumLiability ? item.MaximumLiability : ' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '83px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{' '}
															</Text>
														</View>
													</>
												) : (
													' '
												)}

												{item.co_group === 'Yes' ? (
													<>
														<View
															style={[
																styles.col,
																{
																	width: '88px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{item.co_group ? item.co_group : ' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '129px',
																},
															]}>
															<Text style={[styles.text]}>
																{item.CoAppToWhomPayable
																	? item.CoAppToWhomPayable
																	: ' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '114px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{item.CoAppTerms ? item.CoAppTerms : ' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '111px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{' '}
															</Text>
														</View>

														<View
															style={[
																styles.col,
																{
																	width: '83px',
																},
															]}>
															<Text
																style={[
																	styles.text,
																	{
																		margin: 'auto',
																	},
																]}>
																{item.CoAppMaximumLiability
																	? item.CoAppMaximumLiability
																	: ' '}
															</Text>
														</View>
													</>
												) : (
													' '
												)}
											</View>
										);
								  })
								: ' '}
						</View>

						{/* Specially made view to accomodate "Are any returns being contested" */}
						<View style={[styles.row, styles.ContestedReturns]}>
							<Text
								style={[
									styles.text,
									{
										marginLeft: `${space}px`,
										marginTop: '5px',
										width: '10px',
										height: '10px',
										backgroundColor: 'black',
									},
								]}></Text>
							<Text style={[styles.text, { marginTop: '-10px', marginLeft: '250px' }]}>
								{contingentLiabilitiesQuestionnaireOther?.applicantReturnsCurrentlyBeingContestedTA ||
									'Nil'}
							</Text>
						</View>

						<View style={[styles.row, styles.ContingentLiabilitiesQuestionnaire]}>
							<View style={[styles.row, { width: '194px' }]}>
								<View style={[styles.col, { width: '111px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.ApplicantDeclaredBankruptcy
											? contingentLiabilitiesQuestionnaire.ApplicantDeclaredBankruptcy
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '83px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.CoApplicantDeclaredBankruptcy
											? contingentLiabilitiesQuestionnaire.CoApplicantDeclaredBankruptcy
											: 'No'}
									</Text>
								</View>

								<View style={[styles.col, { width: '111px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.ApplicantAssetsPledged
											? contingentLiabilitiesQuestionnaire.ApplicantAssetsPledged
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '83px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.CoApplicantAssetsPledged
											? contingentLiabilitiesQuestionnaire.CoApplicantAssetsPledged
											: 'No'}
									</Text>
								</View>

								<View style={[styles.col, { width: '111px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.ApplicantDrawnWill
											? contingentLiabilitiesQuestionnaire.ApplicantDrawnWill
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '83px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.CoApplicantDrawnWill
											? contingentLiabilitiesQuestionnaire.CoApplicantDrawnWill
											: 'No'}
									</Text>
								</View>

								<View style={[styles.col, { width: '111px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.ApplicantFinancialPlan
											? contingentLiabilitiesQuestionnaire.ApplicantFinancialPlan
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '83px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaire.CoApplicantFinancialPlan
											? contingentLiabilitiesQuestionnaire.CoApplicantFinancialPlan
											: 'No'}
									</Text>
								</View>
							</View>
						</View>

						<View style={[styles.row, styles.ContingentLiabilitiesQuestionnaireOther]}>
							<View style={[styles.row, { width: '222px' }]}>
								<View style={[styles.col, { width: '76px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.ApplicantLettersCreditSuretyBonds
											? contingentLiabilitiesQuestionnaireOther.ApplicantLettersCreditSuretyBonds
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '100px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.CoApplicantLettersCreditSuretyBonds
											? contingentLiabilitiesQuestionnaireOther.CoApplicantLettersCreditSuretyBonds
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '46px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.ApplicantLettersCreditSuretyBondsTA
											? contingentLiabilitiesQuestionnaireOther.ApplicantLettersCreditSuretyBondsTA
											: '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '76px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.ApplicantSuitsLegalActions
											? contingentLiabilitiesQuestionnaireOther.ApplicantSuitsLegalActions
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '100px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.CoApplicantSuitsLegalActions
											? contingentLiabilitiesQuestionnaireOther.CoApplicantSuitsLegalActions
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '46px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.ApplicantSuitsLegalActionsTA
											? contingentLiabilitiesQuestionnaireOther.ApplicantSuitsLegalActionsTA
											: '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '76px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.applicantTaxObligations
											? contingentLiabilitiesQuestionnaireOther.applicantTaxObligations
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '100px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.coApplicantTaxObligations
											? contingentLiabilitiesQuestionnaireOther.coApplicantTaxObligations
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '46px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.applicantTaxObligationsTA
											? contingentLiabilitiesQuestionnaireOther.applicantTaxObligationsTA
											: '0'}
									</Text>
								</View>

								<View style={[styles.col, { width: '76px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiability
											? contingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiability
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '100px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.coApplicantTotalEstimatedTaxLiability
											? contingentLiabilitiesQuestionnaireOther.coApplicantTotalEstimatedTaxLiability
											: 'No'}
									</Text>
								</View>
								<View style={[styles.col, { width: '46px', height: '11px' }]}>
									<Text style={[styles.text, { margin: 'auto' }]}>
										{contingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiabilityOther
											? contingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiabilityOther
											: '0'}
									</Text>
								</View>
							</View>
						</View>

						<View style={[styles.row, styles.ApplicantTotalEstimatedTaxLiabilityInformation]}>
							<View style={[styles.col]}>
								<Text style={[styles.text, { margin: 'auto' }]}>
									{contingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiabilityInformation
										? contingentLiabilitiesQuestionnaireOther.applicantTotalEstimatedTaxLiabilityInformation
										: 'No'}
								</Text>
							</View>
						</View>
					</Page>

					{/*
          <Page style={styles.body}>

            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}` )} fixed />

            <Text style={styles.author}>MN Limited Broker #{(obj) ? obj.id : " "}</Text>

            <Text style={styles.title}>Personal Financial Statement</Text>
            <Text style={styles.text}>I may apply for a credit extension, loan or other financial accommodation alone or together with someone else ("Co-Applicant").  If I apply with a Co-Applicant and our combined assets and debts can meaningfully and fairly be presented together, the Co-Applicant and I may complete this required statement and any supporting schedules jointly.  Otherwise separate forms and schedules are required.</Text>
            <Text style={styles.subtitle}>Gray cells are formulated.  Please complete schedules below.</Text>

            <View style={styles.row}>
              <View style={[styles.col, styles.width50]}>

                <Text style={[styles.subtitle, styles.txtCenter]}>Applicant</Text>

                <Text style={[styles.text]}>Full Name: {(obj) ? obj.AboutYourSelf.FullName : " "}</Text>
                <Text style={[styles.text]}>Address: {(obj) ? obj.YourLocation.StreetAddress : " "}</Text>
                <Text style={[styles.text]}>{(obj) ? obj.YourLocation.City : " "} / {(obj) ? obj.YourLocation.State : " "} / {(obj) ? obj.YourLocation.Zip : " "}</Text>

                <View style={styles.row}>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>Since: {(obj) ? obj.YourLocation.SinceYear : " "}</Text>
                  </View>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>{(obj) ? obj.YourLocation.PropertyType : " "} {(obj) ? (obj.YourLocation.PropertyType === "rent") ? "$"+obj.YourLocation.MonthlyPayment+"/Month" : " " : " "}</Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>Social Security # {(obj) ? obj.AboutYourSelf.SocialSecurity : " "}</Text>
                  </View>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>Date Of Birth {(obj) ? obj.AboutYourSelf.DateOfBirth : " "}</Text>
                  </View>
                </View>

                <Text style={[styles.text]}>Phone {(obj) ? obj.YourLocation.PhoneNumber : " "}</Text>

              </View>
              <View style={[styles.col, styles.width50]}>

                <Text style={[styles.subtitle, styles.txtCenter]}>Co-Applicant</Text>
                <Text style={[styles.text]}>Full Name: {(obj) ? obj.AboutYourCoAplicant.FullName : " "}</Text>
                <Text style={[styles.text]}>Address: {(obj) ? obj.CoAplicantLocation.streetAddress : " "}</Text>
                <Text style={[styles.text]}>{(obj) ? obj.CoAplicantLocation.city : " "} / {(obj) ? obj.CoAplicantLocation.state : " "} / {(obj) ? obj.CoAplicantLocation.zip : " "}</Text>

                <View style={styles.row}>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>Since: {(obj) ? obj.CoAplicantLocation.since : " "}</Text>
                  </View>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>{(obj) ? obj.CoAplicantLocation.propertyType : " "} {(obj) ? (obj.CoAplicantLocation.propertyType === "rent") ? "$"+obj.CoAplicantLocation.rent+"/Month" : " " : " "}</Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>Social Security # {(obj) ? obj.AboutYourCoAplicant.SocialSecurity : " "}</Text>
                  </View>
                  <View style={[styles.col, styles.width50]}>
                    <Text style={[styles.text]}>Date Of Birth {(obj) ? obj.AboutYourCoAplicant.DateOfBirth : " "}</Text>
                  </View>
                </View>

                <Text style={[styles.text]}>Phone {(obj) ? obj.CoAplicantLocation.phone : " "}</Text>

              </View>
            </View>

          </Page>
          */}
				</Document>
			</PDFViewer>
		);
	}
};

Font.register({
	family: 'Oswald',
	src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
	viewer: {
		width: window.innerWidth, //the pdf viewer will take up all of the width and height
		height: window.innerHeight,
	},
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	page1_img: {},
	title: {
		fontSize: 24,
		textAlign: 'center',
		fontFamily: 'Oswald',
	},
	author: {
		fontSize: 12,
		textAlign: 'center',
		marginBottom: 40,
	},
	subtitle: {
		fontSize: 18,
		margin: 12,
		fontFamily: 'Oswald',
	},
	image: {
		marginVertical: 15,
		marginHorizontal: 100,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},

	row: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		// alignItems: "baseline",
		// alignContent: "baseline",
	},
	col: {
		margin: 0,
	},
	item: {
		fontFamily: 'Poppins',
		fontSize: 10,
		color: '#000',
	},

	txtLeft: {
		textAlign: 'left',
	},
	txtRight: {
		textAlign: 'right',
	},
	txtCenter: {
		textAlign: 'center',
	},

	Border: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#DAE6ED',
	},
	BorderTop: {
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderTopColor: '#DAE6ED',
	},
	BorderRight: {
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: '#DAE6ED',
	},
	BorderBottom: {
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#DAE6ED',
	},
	BorderLeft: {
		borderLeftWidth: 1,
		borderLeftStyle: 'solid',
		borderLeftColor: '#DAE6ED',
	},
	BorderNo: {
		border: 0,
	},

	width16: {
		width: '16.5%',
	},
	width25: {
		width: '25%',
	},
	width30: {
		width: '30%',
	},
	width33: {
		width: '33.33%',
	},
	width35: {
		width: '35%',
	},
	width50: {
		width: '50%',
	},
	width65: {
		width: '65%',
	},
	width75: {
		width: '75%',
	},
	width80: {
		width: '80%',
	},
	width90: {
		width: '90%',
	},
	width100: {
		width: '100%',
	},

	text: {
		margin: 1.8,
		fontSize: 6.5,
		textAlign: 'justify',
		fontFamily: 'Times-Roman',
	},
	applicant_co_applicant: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '165px',
		left: '35px',
	},
	CompleteForm: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '377px',
		left: '35px',
	},
	CompleteForm2: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '377px',
		left: '300px',
	},
	YourJobStatusDetail: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '92px',
		left: '252px',
		width: '45px',
	},
	YourJobExpense: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '92px',
		left: '515px',
		width: '45px',
	},
	CashSaving: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '338px',
		left: '35px',
	},
	InsurancePolicy: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '586px',
		left: '35px',
	},
	ShareBond: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '457px',
		left: '35px',
	},
	MortgageContract: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '72px',
		left: '35px',
	},
	RealEstatePersonal: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '158px',
		left: '35px',
	},
	RealEstateInvestment: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '205px',
		left: '35px',
	},
	RealEstateInvestmentNo: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '279px',
		left: '35px',
	},
	ProfitPension: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '376px',
		left: '35px',
	},
	PayableLoan: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '484px',
		left: '35px',
	},
	ContingentLiabilities: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '93px',
		left: '35px',
	},
	ContestedReturns: {
		position: 'absolute',
		top: '193px',
		left: '205px',
	},
	ContingentLiabilitiesQuestionnaire: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '143px',
		left: '366px',
	},
	ContingentLiabilitiesQuestionnaireOther: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '229px',
		left: '338px',
	},
	ApplicantTotalEstimatedTaxLiabilityInformation: {
		// backgroundColor: 'green',
		// opacity: 0.5,
		position: 'absolute',
		top: '285px',
		left: '35px',
		padding: 2,
	},
});

export default PDF;
