import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from "react-bootstrap/Form";
import { Link, useNavigate } from 'react-router-dom';
import { getApplication, updateApplication } from './../../../firebase';

function BussinessForm() {
	// const {
	//     register,
	//     handleSubmit,
	//     // watch,
	//     formState: { errors },
	// } = useForm();

	const navigate = useNavigate();

	const [form, setForm] = useState({
		bussinessName: '',
		bussinessAddress: '',
		signorSignature: '',
		propertyStatus: false,
		propertyAddress: '',
	});
	const [errors, setErrors] = useState({
		bussinessName: '',
		bussinessAddress: '',
		signorSignature: '',
		propertyStatus: '',
		propertyAddress: '',
	});
	const handleChange = (e) => {
		setErrors({ ...errors, [e.target.name]: '' });
		setForm({ ...form, [e.target.name]: e.target.value });
	};
	const createApplicationDefault = localStorage.getItem('createApplicationDefault');

	const onSubmit = async (e) => {
		e.preventDefault();
		console.log({ e });

		window.scrollTo({ top: 0, behavior: 'smooth' });

		let errorsObj = { ...errors };

		if (!form.bussinessName) errorsObj = { ...errorsObj, bussinessName: 'Business Name is required' };
		if (!form.bussinessAddress) errorsObj = { ...errorsObj, bussinessAddress: 'Business Address is required' };
		if (!form.signorSignature) errorsObj = { ...errorsObj, signorSignature: 'Signor Signature is required' };
		if (form.propertyStatus && form.propertyAddress === '')
			errorsObj = { ...errorsObj, propertyAddress: 'Property Address is required' };
		if (
			!form.bussinessName ||
			!form.bussinessAddress ||
			!form.signorSignature ||
			(form.propertyStatus && form.propertyAddress === '')
		)
			return setErrors(errorsObj);

		const saveData = {
			BussinessName: form.bussinessName,
			Address: form.bussinessAddress,
			SignorSignature: form.signorSignature,
			PropertyAddress: form.propertyStatus ? form.propertyAddress : 'TBD',
		};

		await updateApplication(createApplicationDefault, { BussinessDetail: saveData });
		// Firebase Store, Create new Record and Store Data

		navigate('/dashboard/share-bond-flow');
	};

	const fetchPost = async () => {
		const getApplicationDefault = await getApplication(createApplicationDefault);
		console.log({ getApplicationDefault });
		// if (getApplicationDefault.BussinessDetail)
		//     setForm({ bussinessName: getApplicationDefault.BussinessDetail.BussinessName, bussinessAddress: getApplicationDefault.BussinessDetail.Address, signorSignature: getApplicationDefault.BussinessDetail.SignorSignature });
		if (getApplicationDefault?.BussinessDetail?.PropertyAddress !== 'TBD')
			setForm({
				...form,
				propertyStatus: true,
				propertyAddress: getApplicationDefault.BussinessDetail.PropertyAddress,
				bussinessName: getApplicationDefault.BussinessDetail.BussinessName,
				bussinessAddress: getApplicationDefault.BussinessDetail.Address,
				signorSignature: getApplicationDefault.BussinessDetail.SignorSignature,
			});
		else
			setForm({
				...form,
				bussinessName: getApplicationDefault.BussinessDetail.BussinessName,
				bussinessAddress: getApplicationDefault.BussinessDetail.Address,
				signorSignature: getApplicationDefault.BussinessDetail.SignorSignature,
				propertyStatus: false,
				propertyAddress: '',
			});
	};

	useEffect(() => {
		fetchPost();
	}, []);

	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-blue pulse text-center py-3'>Tell us about your business?</h2>
				<form onSubmit={onSubmit}>
					<Row className='py-5'>
						<Col>
							<label>Business Name</label>
							<input
								type='text'
								onChange={handleChange}
								name='bussinessName'
								value={form.bussinessName}
							/>
							{errors.bussinessName && <span>Business name is required</span>}
						</Col>
					</Row>

					<Row className='py-3 justify-content-md-center'>
						<Col>
							<label>Business Address</label>
							<input
								type='text'
								onChange={handleChange}
								name='bussinessAddress'
								value={form.bussinessAddress}
							/>
							{errors.bussinessAddress && <span>Bussiness Address is required</span>}
						</Col>
					</Row>

					<Row className='py-3 justify-content-md-center'>
						<Col>
							<label>Authorized Signor</label>
							<input
								type='text'
								onChange={handleChange}
								name='signorSignature'
								value={form.signorSignature}
							/>
							{errors.signorSignature && <span>Signor Signature is required</span>}
						</Col>
					</Row>

					<Row className='py-3 justify-content-md-center'>
						<Col>
							<label className='form-check-label' htmlFor='propertyStatus'>
								Do you have a property under contract yet
								<span className='text-center text-secondary mx-2'>(check if yes)</span>
							</label>
							<input
								className='form-check-input mx-5'
								type='checkbox'
								role='switch'
								id='propertyStatus'
								name='propertyStatus'
								checked={form.propertyStatus}
								onChange={() => setForm({ ...form, propertyStatus: !form.propertyStatus })}
							/>
						</Col>
					</Row>

					{form.propertyStatus && (
						<Row className='py-3 justify-content-md-center'>
							<Col>
								<label>Property Address</label>
								<input
									type='text'
									onChange={(e) => setForm({ ...form, propertyAddress: e.target.value })}
									name='propertyAddress'
									value={form.propertyAddress}
								/>
								{errors.propertyAddress && <span>Property Address is required</span>}
							</Col>
						</Row>
					)}

					<Row className='py-5 justify-content-between'>
						<Col xs={5} md={3}>
							<Link to='/dashboard/bussiness-entity' className='cl-btn position-absolute'>
								{' '}
								Back{' '}
							</Link>
						</Col>
						<Col xs={5} md={3}>
							<input className='cl-btn' type='submit' value='Save & Continue' />
						</Col>
					</Row>
				</form>
			</Container>
		</div>
	);
}

export default BussinessForm;
