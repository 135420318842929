import React from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Logo from "../../assets/img/creativelendinglogo.png";
import { Outlet } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { checkAdmin, logout } from "../../firebase";
import { useState, useEffect } from "react";

export const Header = () => {
  const auth = getAuth();

  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);

  const logout_btn = () => {
    localStorage.clear();

    logout();
    setTimeout(() => {
      navigate("/login");
    }, 500);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid) {
          checkAdmin(user.uid).then((res) => {
            if (res) {
              setAdmin(true);
            } else {
              console.log("Not an admin");
            }
          });
        }
      } else navigate("/login");
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className="header-bg">
      <Container>
        <Row>
          <Col md={5}>
            <div className="logo-container">
              <Link to="/dashboard">
                <img src={Logo} />
              </Link>
            </div>
          </Col>
          <Col md={7}>
            <div className="menu-container">
              <Nav
                className="justify-content-end me-auto"
                activeKey="/dashboard"
              >
                <Nav.Item>
                  <Nav.Link href="/dashboard">My Account</Nav.Link>
                </Nav.Item>

                {admin && (
                  <Nav.Item>
                    <Nav.Link as={Link} to={"/admin"}>
                      Admin Panel
                    </Nav.Link>
                  </Nav.Item>
                )}

                <Nav.Item>
                  <Nav.Link href="/dashboard/intro">
                    Financial Statment
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  {/* <Nav.Link> */}
                  <a
                    className="nav-link"
                    style={{ textDecoration: "none" }}
                    href={`mailto:support@nayyerdev.com`}
                  >
                    Report bug
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-1" onClick={logout_btn}>
                    Logout
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
  <Outlet />;
};
