// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDoc,
  getDocs,
  deleteDoc,
  collection,
  where,
  addDoc,
  updateDoc,
  doc,
  orderBy,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPuDgaUd4LrZzx4nS-gFp6xe4Lrmiffgs",
  authDomain: "creative-tending-llc.firebaseapp.com",
  databaseURL: "https://creative-tending-llc-default-rtdb.firebaseio.com",
  projectId: "creative-tending-llc",
  storageBucket: "creative-tending-llc.appspot.com",
  messagingSenderId: "390191607268",
  appId: "1:390191607268:web:24755bdc670ddef887abf1",
  measurementId: "G-ZPTBR3R35M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    console.log({ docs: docs.docs[0].data() });
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        admin: false,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem("creative_lending_user", email);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const createApplicationDefault = async () => {
  try {
    const auth = getAuth();

    const docRef = await addDoc(collection(db, "Applications"), {
      ContingentLiabilitiesQuestionnaireOther: {
        applicantLettersCreditSuretyBonds: "",
        coApplicantLettersCreditSuretyBonds: "",
        applicantSuitsLegalActions: "",
        coApplicantSuitsLegalActions: "",
        applicantTaxObligations: "",
        coApplicantTaxObligations: "",
        applicantReturnsCurrentlyBeingContested: "",
        coApplicantReturnsCurrentlyBeingContested: "",
        applicantLettersCreditSuretyBondsTA: "",
        applicantSuitsLegalActionsTA: "",
        applicantTaxObligationsTA: "",
        applicantReturnsCurrentlyBeingContestedTA: "",
        applicantTotalEstimatedTaxLiability: "",
        coApplicantTotalEstimatedTaxLiability: "",
        applicantTotalEstimatedTaxLiabilityOther: "",
        applicantTotalEstimatedTaxLiabilityInformation: "",
      },
      ContingentLiabilitiesQuestionnaire: {
        ApplicantDeclaredBankruptcy: "",
        CoApplicantDeclaredBankruptcy: "",
        ApplicantAssetsPledged: "",
        CoApplicantAssetsPledged: "",
        ApplicantDrawnWill: "",
        CoApplicantDrawnWill: "",
        ApplicantFinancialPlan: "",
        CoApplicantFinancialPlan: "",
      },
      ContingentLiabilities: [
        {
          group: "",
          ToWhomPayable: "",
          Terms: "",
          MaximumLiability: "",
          co_group: "",
          CoAppToWhomPayable: "",
          CoAppTerms: "",
          CoAppMaximumLiability: "",
        },
      ],
      ShareBond: [
        {
          SharesBondAmount: "",
          Description: "",
          WhoseNameRegistered: "",
          group: "",
          Cost: "",
          PresentMarketValue: "",
          ExchangeListedUnlisted: "",
          ControlledRestricted: "",
        },
      ],
      PayableLoan: [
        {
          ToWhomPayable: "",
          Address: "",
          InterestRate: "",
          CollateralIndicateUnsecured: "",
          MonthlyPayment: "",
          MaturityDate: "",
          UnpaidBalance: "",
        },
      ],
      ProfitPension: [
        {
          NameInstitutionPlan: "",
          acct: "",
          AccountBalance: "",
          AmountTotallyVested: "",
          Loans: "",
        },
      ],
      RealEstateInvestment: [
        {
          LegalOwner: "",
          PropertyAddress: "",
          PurchasePrice: "",
          PurchaseYear: "",
          MarketValue: "",
          PresentLoanBal: "",
          InterestRate: "",
          LoanMaturityDate: "",
          MonthlyRentalIncome: "",
          MonthlyPayment: "",
          Lender: "",
          InvestmentOwnedSomeone: "",
          InvestmentOwnedSomeoneOwnership: "",
        },
      ],
      RealEstatePersonal: [
        {
          LegalOwner: "",
          PropertyAddress: "",
          PurchasePrice: "",
          PurchaseYear: "",
          MarketValue: "",
          PresentLoanBal: "",
          InterestRate: "",
          LoanMaturityDate: "",
          MonthlyRentalIncome: "",
          MonthlyPayment: "",
          Lender: "",
        },
      ],
      MortgageContract: [
        {
          NameDebtor: "",
          DescriptionProperty: "",
          FirstSecondLien: "",
          DateMaturity: "",
          RepaymentTerms: "",
          BalanceDue: "",
        },
      ],
      InsurancePolicy: [
        {
          InsuranceCompany: "",
          Insured: "",
          Beneficiary: "",
          FaceValue: "",
          CashValue: "",
          Loans: "",
        },
      ],
      CashSaving: [
        {
          NameOfBank: "",
          TypeAccount: "",
          WhoseName: "",
          AccountBalance: "",
          group: "",
        },
      ],
      YourLocation: {
        StreetAddress: "",
        City: "",
        State: "",
        Zip: "",
        Country: "",
        PhoneNumber: "",
        SinceYear: "",
        MonthlyPayment: "",
        PropertyType: "",
      },
      YourJobStatusDetail: {
        YourIncome: 0,
        BonusesCommissions: 0,
        InterestIncome: 0,
        DividendIncome: 0,
        CapitalGains: 0,
        PartnershipIncome: 0,
        OtherInvestmentIncome: 0,
        OtherIncome: 0,
      },
      YourJobStatus: {
        WhoYourEmployer: "",
        WhatPositionTitle: "",
        WhenDidYouStart: "",
        StreetAddress: "",
        City: "",
        State: "",
        Zip: "",
        WorkPhone: "",
      },
      YourJobStatus: {
        FederalIncomeOtherTaxes: 0,
        StateIncomeOtherTaxes: 0,
        RentalPaymentsCoopCondoMaintenance: 0,
        MortgagePayments: 0,
        PropertyTaxes: 0,
        ResidentialSchedule5: 0,
        InvestmentSchedule5: 0,
        Residential: 0,
        Investment: 0,
        InterestPrincipalPaymentsLoans: 0,
        InsuranceIncludingAutoHomeLifeDisabilityOther: 0,
        InvestmentsIncludingTaxShelters: 0,
        AlimonyChildSupport: 0,
        Tuition: 0,
        OtherLivingExpenses: 0,
        MedicalExpenses: 0,
        OtherExpenseList: 0,
      },
      RealEstateSecond: {
        LegalOwner: "",
        PropertyAddress: "",
        PurchasePrice: "",
        PurchaseYear: "",
        MarketValue: "",
        PresentLoanBal: "",
        InterestRate: "",
        LoanMaturityDate: "",
        MonthlyRentalIncome: "",
        MonthlyPayment: "",
        Lender: "",
      },
      CompleteForm: {
        valueCompanies: "",
        valueCompaniesAmount: "",
        automobiles: "",
        automobilesAmount: "",
        otherLiabilities: "",
        otherLiabilitiesAmount: "",
        loansAgainstRetirement: "",
        loansAgainstRetirementAmount: "",
        taxesFederalState: "",
        taxesFederalStateAmount: "",
        installmentLoansContracts: "",
        installmentLoansContractsAmount: "",
        shortTermNotes: "",
        shortTermNotesAmount: "",
        otherTangibleAssets: "",
        otherTangibleAssetsAmount: "",
        personalProperty: "",
        personalPropertyAmount: "",
      },
      CoApplicantJobStatus: {
        employer: "",
        position: "",
        start: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
      },
      CoApplicant: {
        FullName: "",
        SocialSecurity: "",
        DateOfBirth: "",
        Status: "",
        Dependents: "",
      },
      CoAplicantLocation: {
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone: "",
        since: "",
        rent: "",
        propertyType: "",
      },
      AboutYourCoAplicant: {
        FullName: "",
        SocialSecurity: "",
        DateOfBirth: "",
        Status: "",
        Dependents: "",
      },
      AboutYourSelf: {
        FullName: "",
        SocialSecurity: "",
        DateOfBirth: "",
        Status: "",
        dependentsAYS: "",
        dependentsAgeAYS: [""],
        childrenAYS: "",
        childrenAgeAYS: [""],
      },
      TransactionType: {
        TransactionType: "",
        TransactionTypeApply: "",
      },
      ApplicantDetail: {
        "Employer Name": "",
        "Phone Work": "",
        City: "",
        "Company Address": "",
        Country: "",
        "Date of Birth": "",
        "Full Name": "",
        "Job Title": "",
        "Marital Status": "",
        "Monthly Rent": "",
        "Phone Residence": "",
        "Property Type": "",
        Since: "",
        "Social Security": "",
        "Start Job": "",
        State: "",
        "Street Address": "",
        Zip: "",
      },
      CoApplicantDetail: {
        "Employer Name": "",
        "Phone Work": "",
        City: "",
        "Company Address": "",
        Country: "",
        "Date of Birth": "",
        "Full Name": "",
        "Job Title": "",
        "Marital Status": "",
        "Monthly Rent": "",
        "Phone Residence": "",
        "Property Type": "",
        Since: "",
        "Social Security": "",
        "Start Job": "",
        State: "",
        "Street Address": "",
        Zip: "",
      },
      "Schedule 1": {
        1: {
          "Account Balance": "",
          "Account Type": "",
          Name: "",
          "Name of Bank": "",
          Pledged: true,
        },
        2: {
          "Account Balance": "",
          "Account Type": "",
          Name: "",
          "Name of Bank": "",
          Pledged: true,
        },
        "Schedule 1": true,
      },
      "Schedule 2": {
        1: {
          Controlled: "",
          Cost: "",
          Description: "",
          Exchange: "",
          "Market Value": "",
          Name: "",
          "Number of Shares": "",
          Pledged: true,
        },
        2: {
          Controlled: "",
          Cost: "",
          Description: "",
          Exchange: "",
          "Market Value": "",
          Name: "",
          "Number of Shares": "",
          Pledged: true,
        },
        "Schedule 2": true,
      },
      "Schedule 3": {
        1: {
          Beneficiary: "",
          "Cash Value": "",
          "Face Value": "",
          "Insurance Company": "",
          Insured: "",
          Loans: "",
        },
        2: {
          Beneficiary: "",
          "Cash Value": "",
          "Face Value": "",
          "Insurance Company": "",
          Insured: "",
          Loans: "",
        },
        "Schedule 3": true,
      },
      "Schedule 4": {
        1: {
          "Balance Due": "",
          "Debtor Name": "",
          Description: "",
          Lien: "",
          "Maturity Date": "",
          "Repayment Terms": "",
        },
        2: {
          "Balance Due": "",
          "Debtor Name": "",
          Description: "",
          Lien: "",
          "Maturity Date": "",
          "Repayment Terms": "",
        },
        "Schedule 4": true,
      },
      "Schedule 5 Investment": {
        1: {
          "Interest Rate": "",
          "Legal Owner": "",
          Lender: "",
          "Market Value": "",
          Maturity: "",
          "Monthly Payment": "",
          "Monthly Rental Income": "",
          "Present Loan Bal": "",
          "Property Address": "",
          "Purchase Price": "",
          "Purchase Year": "",
        },
        2: {
          "Interest Rate": "",
          "Legal Owner": "",
          Lender: "",
          "Market Value": "",
          Maturity: "",
          "Monthly Payment": "",
          "Monthly Rental Income": "",
          "Present Loan Bal": "",
          "Property Address": "",
          "Purchase Price": "",
          "Purchase Year": "",
        },
        "Schedule 5 Investment": true,
      },
      "Schedule 5 Partnership": {
        1: {
          "Legal Owner": "",
          Lender: "",
          "Market Value": "",
          "Monthly Payment": "",
          Ownership: "",
          "Present Loan Bal": "",
          "Property Address": "",
          "Purchase Price": "",
          "Purchase Year": "",
        },
        2: {
          "Legal Owner": "",
          Lender: "",
          "Market Value": "",
          "Monthly Payment": "",
          Ownership: "",
          "Present Loan Bal": "",
          "Property Address": "",
          "Purchase Price": "",
          "Purchase Year": "",
        },
        "Schedule 5 Partnership": true,
      },
      "Schedule 5 Personal": {
        1: {
          "Interest Rate": "",
          "Legal Owner": "",
          Lender: "",
          "Market Value": "",
          "Maturity Date": "",
          "Monthly Payment": "",
          "Monthly Rental Income": "",
          "Present Loan Bal": "",
          "Property Address": "",
          "Purchase Price": "",
          "Purchase Year": "",
        },
        2: {
          "Interest Rate": "",
          "Legal Owner": "",
          Lender: "",
          "Market Value": "",
          "Maturity Date": "",
          "Monthly Payment": "",
          "Monthly Rental Income": "",
          "Present Loan Bal": "",
          "Property Address": "",
          "Purchase Price": "",
          "Purchase Year": "",
        },
        "Schedule 5 Personal": true,
      },
      "Schedule 6": {
        1: {
          "Account Balance": "",
          "Account Type": "",
          "Institution Name": "",
          Loans: "",
          "Totally Vested": "",
        },
        2: {
          "Account Balance": "",
          "Account Type": "",
          "Institution Name": "",
          Loans: "",
          "Totally Vested": "",
        },
        "Schedule 6": true,
      },
      status: "PENDING",
      created: Date.now(),
      updated: Date.now(),
      userID: auth.currentUser.uid,
    });
    // console.log("firebase createApplicationDefault docRef", docRef.id);
    localStorage.setItem("createApplicationDefault", docRef.id);
    return docRef.id;
  } catch (e) {
    console.error("firebase createApplicationDefault Error", e);
  }
};

const updateApplication = async (docID, data) => {
  // console.log("firebase updateApplication docID", docID);
  try {
    const updatedData = {
      ...data,
      updated: Date.now(),
      last_loc: window.location.href,
    };
    // data["updated"] = Date.now();
    const docRef = await updateDoc(doc(db, "Applications", docID), updatedData);
    // const docRef = await db.collection("Applications").doc(id).set(data);
    // console.log("firebase updateApplication docRef", docRef.id);
    return docRef;
  } catch (e) {
    console.error("firebase updateApplication Error", e);
  }
};

const getApplication = async (docID) => {
  // console.log("firebase getApplication docID", docID);
  try {
    const docRef = await getDoc(doc(db, "Applications", docID)).then((snap) => {
      if (!snap.exists()) throw new Error("not-found"); // document missing
      // console.log("firebase getApplication snap", snap.data());
      return snap.data();
    });
    // const docRef = await updateDoc(doc(db, "Applications", docID), data);
    // const docRef = await db.collection("Applications").doc(id).set(data);
    // console.log("firebase getApplication docRef", docRef.id);
    return docRef;
  } catch (e) {
    console.error("firebase getApplication Error", e);
  }
};

const getApplications = async () => {
  try {
    const auth = getAuth();
    const userID = auth.currentUser.uid;
    const docRef = await getDocs(
      query(collection(db, "Applications"), orderBy("updated", "desc"))
    ).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      return newData;
    });
    const response = docRef.filter((item) => item.userID === userID);
    return response;
  } catch (e) {
    console.error("firebase getApplications Error", e);
  }
};

const deleteApplication = async (docID) => {
  try {
    console.log("deleting");
    const docRef = await deleteDoc(doc(db, "Applications", docID));
    console.log({ docRef });
    return docRef;
  } catch (e) {
    console.error("firebase deleteApplication Error", e);
  }
};

// Fetch all applications of the user whose email is given
const fetchApplications = async (timespan) => {
  const { startDate, endDate } = getDates(timespan);
  console.log({ startDate, endDate });
  try {
    const docRef = await getDocs(
      query(
        collection(db, "Applications"),
        where("status", "==", "COMPLETED"),
        where("updated", ">=", startDate),
        // where('updated', '<=', endDate),
        orderBy("updated", "desc")
      )
    );
    const response = docRef.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log({ response });
    return response;
  } catch (e) {
    console.error("firebase fetchApplicatins Error", e);
  }
};

const checkAdmin = async (userID) => {
  try {
    const docRef = await getDocs(
      query(collection(db, "users"), where("uid", "==", userID))
    );
    const response = docRef.docs[0].data().admin;
    return response;
  } catch (e) {
    console.log("firebase checkAdmin Error", e);
    console.error("firebase checkAdmin Error", e);
  }
};

const getDates = (timespan) => {
  const now = new Date();
  let startDate, endDate;

  if (timespan === "daily") {
    startDate = new Date(now.setUTCHours(0, 0, 0, 0));
    endDate = new Date(now);
  } else if (timespan === "weekly") {
    startDate = new Date(now);
    startDate.setDate(startDate.getDate() - 7);
    endDate = new Date(now);
  } else if (timespan === "monthly") {
    startDate = new Date(now);
    startDate.setMonth(startDate.getMonth() - 1);
    endDate = new Date(now);
  } else if (timespan === "annually") {
    startDate = new Date(now);
    startDate.setFullYear(startDate.getFullYear() - 1);
    endDate = new Date(now);
  } else {
    startDate = new Date(0); // Default to the beginning of time
    endDate = new Date();
  }
  startDate = startDate.getTime();
  endDate = endDate.getTime();
  return { startDate, endDate };
};

// Give the id of a user, fetch their data
const fetchUser = async (userID) => {
  try {
    const docRef = await getDocs(
      query(collection(db, "users"), where("uid", "==", userID))
    );
    const response = docRef.docs[0].data();
    return response;
  } catch (e) {
    console.error("firebase fetchUser Error", e);
  }
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  createApplicationDefault,
  updateApplication,
  getApplication,
  getApplications,
  deleteApplication,
  fetchApplications,
  checkAdmin,
  fetchUser,
};
