import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { getApplication, updateApplication } from './../../../firebase';

const BussinessEntity = () => {
	const navigate = useNavigate();

	const onSubmit = async () => {
		const storeData = {
			BussinessName: 'TBD LLC',
			PropertyAddress: 'TBD',
		};
		const createApplicationDefault = localStorage.getItem('createApplicationDefault');

		const res = await updateApplication(createApplicationDefault, { BussinessDetail: storeData });
		navigate('/dashboard/share-bond-flow');
	};
	return (
		<div className='body-bg py-5'>
			<Container>
				<h2 className='step-head-gray pulse text-center py-3'>
					You will be required to have a Business Entity. Do you have one set up yet?
				</h2>
				<Row className='py-5 justify-content-md-center'>
					<Col xs={3} className='text-center'>
						<Link to='/dashboard/bussiness-form'>
							<button className='cl-btn-blue big'>Yes, I do</button>
						</Link>
					</Col>
					<Col xs={3} className='text-center'>
						<button className='cl-btn-gray big' onClick={onSubmit}>
							No, I don't
						</button>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BussinessEntity;
