import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getApplication, updateApplication } from "./../../../firebase";

export const YourLocation = () => {

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  // const [streetAddressCAL, setStreetAddressCAL] = useState("");
  const [StreetAddress, setStreetAddress] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Zip, setZip] = useState("");
  const [Country, setCountry] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [SinceYear, setSinceYear] = useState("");
  const [MonthlyPayment, setMonthlyPayment] = useState("");
  const [PropertyType, setPropertyType] = useState("");

  const createApplicationDefault = localStorage.getItem("createApplicationDefault");

  const onSubmit = async (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log({ data })
    const dataSave = {
      StreetAddress: StreetAddress,
      City: City,
      State: State,
      Zip: Zip,
      Country: Country,
      PhoneNumber: PhoneNumber,
      SinceYear: SinceYear,
      MonthlyPayment: MonthlyPayment,
      PropertyType: PropertyType,
    };

    await updateApplication(createApplicationDefault, { YourLocation: dataSave });
    // Firebase Store, Create new Record and Store Data

    navigate("/dashboard/your-job-status");
  };

  const fetchPost = async () => {
    const getApplicationDefault = await getApplication(createApplicationDefault);
    console.log({ getApplicationDefault })
    // setStreetAddressCAL(getApplicationDefault.YourLocation.streetAddress);
    setStreetAddress(getApplicationDefault.YourLocation.StreetAddress);
    setCity(getApplicationDefault.YourLocation.City);
    setState(getApplicationDefault.YourLocation.State);
    setZip(getApplicationDefault.YourLocation.Zip);
    setCountry(getApplicationDefault.YourLocation.Country);
    setPhoneNumber(getApplicationDefault.YourLocation.PhoneNumber);
    setSinceYear(getApplicationDefault.YourLocation.SinceYear);
    setMonthlyPayment(getApplicationDefault.YourLocation.MonthlyPayment);
    setPropertyType(getApplicationDefault.YourLocation.PropertyType);
  };

  useEffect(() => { fetchPost() }, []);


  return (
    <div className="body-bg py-5">
      <Container>
        <h2 className="step-head-gray pulse text-center py-3">What is your address?</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="py-5">
            <Col>
              <label>Street Address</label>
              <input type="text" {...register(".StreetAddress")} onChange={(e) => setStreetAddress(e.target.value)} value={StreetAddress} />
              {errors && errors.StreetAddress && (<span>Street Address is required*</span>)}
            </Col>
            <Col>
              <label>City</label>
              <input type="text" {...register(".City")} onChange={(e) => setCity(e.target.value)} value={City} />
              {errors && errors.City && (<span>City is required*</span>)}
            </Col>
            <Col>
              <label>State</label>
              <select {...register(".State")} onChange={(e) => setState(e.target.value)} value={State}>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                <option value="AS">American Samoa</option>
                <option value="GU">Guam</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="PR">Puerto Rico</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="VI">Virgin Islands</option>
                <option value="NA">Not a US City</option>
              </select>
              {errors.State && <span>State Required*</span>}
            </Col>

            <Col>
              <label>Zip*</label>
              <input type="text" {...register(".Zip")} onChange={(e) => setZip(e.target.value)} value={Zip} />
              {errors && errors.Zip && (<span>Zipcode is required*</span>)}
            </Col>
          </Row>
          <Row className="py-3 justify-content-md-center">
            <Col xs={2}>
              <label>Country</label>
              <input type="text" {...register(".Country")} onChange={(e) => setCountry(e.target.value)} value={Country} />
              {errors && errors.Country && (<span>Country is required</span>)}
            </Col>
            <Col xs={2}>
              <label>Phone Number</label>
              <input type="text" {...register(".PhoneNumber")} onChange={(e) => setPhoneNumber(e.target.value)} value={PhoneNumber} />
              {errors && errors.PhoneNumber && (<span>Phone Number is required*</span>)}
            </Col>
            <Col xs={2}>
              <label>Since what year?</label>
              <input type="text" {...register(".SinceYear")} onChange={(e) => setSinceYear(e.target.value)} value={SinceYear} />
              {errors && errors.SinceYear && (<span>Since what year is required*</span>)}
            </Col>

            {(PropertyType) ?
              (PropertyType === "rent") ?
                <Col xs={2}>
                  <label>Monthly Payment</label>
                  <input type="text" {...register(".MonthlyPayment")} onChange={(e) => setMonthlyPayment(e.target.value)} value={MonthlyPayment} />
                  {errors && errors.MonthlyPayment && (<span>Monthly Payment is required*</span>)}
                </Col>
                : "" : ""}

            <Col xs={3}>
              <label>Do you own or rent?</label>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3 py-3">
                  <Form.Check
                    inline
                    label="own"
                    name="propertyType"
                    value="own"
                    type={type}
                    id={`inline-${type}-1`}
                    {...register(".PropertyType")}
                    onChange={(e) => setPropertyType(e.target.value)}
                    checked={PropertyType === "own"}
                  />
                  <Form.Check
                    inline
                    label="rent"
                    name="status"
                    value="rent"
                    type={type}
                    id={`inline-${type}-2`}
                    {...register(".PropertyType")}
                    onChange={(e) => setPropertyType(e.target.value)}
                    checked={PropertyType === "rent"}
                  />
                </div>
              ))}
              {errors.PropertyType && <span>Do you own or rent is Required*</span>}
            </Col>
          </Row>


          <Row className="py-5 justify-content-between">
            <Col xs={5} md={3}>
              <Link to="/dashboard/about-your-self" className="cl-btn position-absolute"> Back </Link>
            </Col>
            <Col xs={5} md={3}>
              <input className="cl-btn" type="submit" value="Save & Continue" />
            </Col>
          </Row>

        </form>
      </Container>
    </div>
  );
};
